import { useImageResolver } from "api/hooks/useImageResolver";
import iconX from "assets/icons/x.svg";
import iconZoomIn from "assets/icons/zoom-in.svg";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Tooltip } from "components/Tooltip/Tooltip";
import { AnimatePresence, motion } from "framer-motion";
import { preloadImage } from "helpers/image";
import { useKey } from "hooks/useKey";
import { memo, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

export interface GalleryProps {
  images: FormImage[];
  size?: "small" | "normal";
  isDeleted?: boolean;
  onRemove?: (image: FormImage) => void;
}

export const Gallery = memo(function Gallery({ images, isDeleted, onRemove, size }: GalleryProps) {
  const { t } = useTranslation();
  const [zoomedImage, setZoomedImage] = useState<FormImage | undefined>(undefined);
  const resolveImage = useImageResolver();

  if (zoomedImage && !images.includes(zoomedImage)) {
    setZoomedImage(undefined);
  }

  useKey(
    "Escape",
    () => {
      setZoomedImage(undefined);
    },
    zoomedImage !== undefined,
  );

  if (images.length === 0) {
    return null;
  }

  return (
    <div className={twJoin("flex items-end", onRemove ? "pt-6" : undefined)}>
      {ReactDOM.createPortal(
        <AnimatePresence>
          {zoomedImage !== undefined ? (
            <motion.div
              className="fixed inset-0 z-50 flex min-h-screen scale-100 items-center justify-center overflow-y-auto"
              role="button"
              aria-hidden
              onClick={() => setZoomedImage(undefined)}
            >
              <motion.div
                className="fixed inset-0 bg-black"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.8 }}
                exit={{ opacity: 0 }}
              />

              <motion.div className="z-0 flex h-screen !w-screen items-center justify-center p-8 md:p-16">
                <motion.img
                  className="block max-h-full max-w-full select-none rounded bg-grey-lightest shadow-xl"
                  src={zoomedImage.url}
                  alt={"description" in zoomedImage ? zoomedImage.description || "" : ""}
                  layoutId={zoomedImage.url}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              </motion.div>
            </motion.div>
          ) : null}
        </AnimatePresence>,
        document.body,
      )}
      <AnimatePresence>
        {images.map((image) => (
          <div
            key={image.url}
            className={twJoin("group relative mr-2 cursor-pointer last:mr-0", isDeleted ? "opacity-30" : undefined)}
            onClick={() => setZoomedImage(image)}
            onMouseEnter={() => {
              if ("id" in image) {
                preloadImage(image.url);
              }
            }}
          >
            <motion.div className="absolute inset-0" layoutId={image.url} />
            <img
              className={twJoin(
                "max-w-full select-none rounded bg-grey-lightest shadow",
                size === "small" ? "max-h-[50px]" : "max-h-36 sm:max-w-xs",
              )}
              loading="lazy"
              src={"id" in image ? resolveImage(image, "large") : image.url}
              alt={"description" in image ? image.description || "" : ""}
            />

            {onRemove ? (
              <Tooltip tooltip={t("component.gallery.remove-image")}>
                <motion.button
                  className={
                    "absolute -right-4 -top-4 flex size-8 items-center justify-center rounded-full bg-grey-darkest shadow transition-opacity hover:bg-grey-darker hover:shadow-md focus:bg-grey-dark focus:outline-none"
                  }
                  type="button"
                  layout
                  exit={{ opacity: 0 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    onRemove(image);
                  }}
                >
                  <Icon name={iconX} size={20} className="text-white" />
                </motion.button>
              </Tooltip>
            ) : null}

            <div className="absolute right-2 top-2 flex size-8 items-center justify-center rounded-full bg-black/40 opacity-0 shadow transition-opacity group-hover:opacity-100">
              <Tooltip tooltip={t("component.gallery.zoom-in-image")}>
                <Icon name={iconZoomIn} size={20} className="text-white" />
              </Tooltip>
            </div>
          </div>
        ))}
      </AnimatePresence>
    </div>
  );
});
