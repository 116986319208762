import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useApi } from "api/hooks/useApi";
import type { TicketCategoryDto } from "api/types";
import iconCheck from "assets/icons/check.svg";
import iconX from "assets/icons/x.svg";
import { Anchor } from "components/Anchor/Anchor";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { ErrorPage } from "components/Error/ErrorPage";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Table } from "components/Table/Table";
import { TagList, transformConstraintToTag } from "components/Tag/TagList";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { useBool } from "hooks/useBool";
import { useResolvedPermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { debounce } from "lodash-es";
import { QUERY_KEYS } from "query-keys";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

import { canManageCategories } from "../permissions";

export function TicketCategoryListPage(): React.ReactNode {
  const projectId = useProjectId();
  const slug = useSlug();
  const { t } = useTranslation();
  const { componentProps, openDeleteModal } = useDeleteModal<string>("rr-delete-modal-modal");
  const canManage = useResolvedPermission(canManageCategories);
  const showFlashToast = useFlashToast();
  const navigate = useNavigate();
  const api = useApi();
  const queryClient = useQueryClient();
  const [audienceHidden, audienceHiddenHandlers] = useBool();

  const {
    data: ticketCategories,
    isLoading,
    error,
  } = useQuery({
    queryKey: QUERY_KEYS.TICKET_CATEGORIES(projectId),
    queryFn: () => api.getTicketCategoriesV1(),
    select: commonAPIDataSelector,
  });

  const deleteTicketCategoryMutationResult = useMutation({
    mutationFn: api.deleteTicketCategoriesByIdV1,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: QUERY_KEYS.TICKET_CATEGORIES(projectId) });
      showFlashToast({
        type: "success",
        title: t("model.repair-category.action.delete.notification.success"),
      });
    },
    onError: () => {
      showFlashToast({
        type: "error",
        title: t("model.repair-category.action.delete.notification.error"),
      });
    },
  });

  const columns = useMemo(() => {
    const helper = createColumnHelper<TicketCategoryDto>();

    return [
      helper.accessor("name", {
        header: t("model.repair-category.name"),
        cell: (cell) =>
          canManage ? (
            <Anchor to={routes.ticketCategories.edit({ slug, id: cell.row.original.id })}>{cell.getValue()}</Anchor>
          ) : (
            <span>{cell.getValue()}</span>
          ),
      }),
      helper.accessor("defaultAssignee", {
        header: t("model.repair-category.assigned"),
        cell: (cell) => <span className="text-grey-darker">{cell.getValue()?.fullName}</span>,
      }),
      helper.accessor("audience", {
        header: t("model.repair-category.audience"),
        cell: (cell) => {
          const audience = cell.getValue();
          if (audience.length === 0) {
            return <i className="text-grey-dark">{t("model.constraint.placeholder")}</i>;
          }

          return <TagList tags={cell.getValue().map(transformConstraintToTag)} limit={3} />;
        },
      }),
      helper.accessor("notificationType", {
        header: t("model.repair-category.notification-type"),
        cell: (cell) => (
          <span className="text-grey-darker">
            {cell.getValue() === "canBeCollective"
              ? t("model.repair-category.notification-type.collective")
              : t("model.repair-category.notification-type.private")}
          </span>
        ),
      }),
      helper.accessor("disabled", {
        header: t("model.repair-category.status"),
        cell: (cell) => (
          <div className="flex justify-center">
            {cell.getValue() ? (
              <Icon name={iconX} className="text-red-dark" />
            ) : (
              <Icon name={iconCheck} className="text-green-darkest" />
            )}
          </div>
        ),
      }),
      helper.accessor("id", {
        header: "",
        cell: (cell) => {
          const actions: ContextMenuAction[] = [];

          if (canManage) {
            actions.push({
              callback: () => {
                navigate(routes.ticketCategories.edit({ slug, id: cell.getValue() }));
              },
              text: t("common.action.edit"),
            });
          }

          if (cell.row.original.canDelete && canManage) {
            actions.push({
              callback: () => openDeleteModal(cell.getValue()),
              text: t("common.action.delete"),
            });
          }

          return (
            <div className="flex justify-end">
              <ContextMenu actions={actions} />
            </div>
          );
        },
      }),
    ];
  }, [canManage, slug, navigate, openDeleteModal, t]);

  const tableInstance = useReactTable<TicketCategoryDto>({
    columns,
    data: ticketCategories?.items ?? [],
    state: {
      columnVisibility: { audience: !audienceHidden },
    },
    getCoreRowModel: getCoreRowModel(),
  });

  const hideColumnsOnMobile = useMemo(
    () =>
      debounce(() => {
        const windowWidth = getWindowSize().width;
        if (windowWidth <= SM_SCREEN_SIZE && !audienceHidden) {
          audienceHiddenHandlers.setTrue();
        }
        if (windowWidth > SM_SCREEN_SIZE && audienceHidden) {
          audienceHiddenHandlers.setFalse();
        }
      }, 200),
    [audienceHidden, audienceHiddenHandlers],
  );

  useEffect(() => {
    hideColumnsOnMobile();
    window.addEventListener("resize", hideColumnsOnMobile);

    return () => {
      window.removeEventListener("resize", hideColumnsOnMobile);
    };
  }, [hideColumnsOnMobile]);

  if (isLoading) {
    return <FullSizeLoader withPadding />;
  }

  if (error) {
    return <ErrorPage error={error} />;
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.repair-category.list.title")}
      subTitle={
        <div className="flex flex-col gap-2">
          {t("page.repair-category.list.subtitle")}
          <Breadcrumbs
            pages={[
              {
                name: t("page.tickets.title"),
                to: routes.tickets.list({ slug }),
              },
              {
                name: t("page.repair-category.list.title"),
              },
            ]}
          />
        </div>
      }
      actions={
        canManage ? (
          <Button type="link" href={routes.ticketCategories.create({ slug })} data-testid="create-category-link">
            {t("model.repair-category.action.create")}
          </Button>
        ) : null
      }
    >
      <DeleteModal
        title={t("model.repair-category.action.delete.confirmation")}
        description={t("model.repair-category.action.delete.description")}
        onDelete={deleteTicketCategoryMutationResult.mutateAsync}
        deleteBtnProps={{
          "data-testid": "repair-categories-action-delete",
        }}
        {...componentProps}
      />
      <div className="overflow-auto">
        <Table<TicketCategoryDto>
          table={tableInstance}
          data-testid="repair-categories-list"
          getRowProps={() => ({
            "data-testid": "repair-categories-item",
          })}
        />
      </div>
    </DocumentPaper>
  );
}

const SM_SCREEN_SIZE = 767;

function getWindowSize() {
  return {
    width: window.innerWidth,
  };
}
