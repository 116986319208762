import { useQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import iconGrid01 from "assets/icons/grid-01.svg";
import iconList from "assets/icons/list.svg";
import iconPlus from "assets/icons/plus.svg";
import { IconButton } from "components/Button/IconButton";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { ErrorPage } from "components/Error/ErrorPage";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Icon } from "components/Icon/Icon";
import { Modal } from "components/Modal/Modal";
import { MonthPicker } from "components/MonthPicker/MonthPicker";
import { Paper } from "components/Paper/Paper";
import { Select } from "components/Select/Select";
import { Capture2, Headline4, Subtitle2 } from "components/Text/Text";
import { addMonths, format, max, parseISO, startOfMonth } from "date-fns";
import { SUPPORT_EMAIL } from "helpers/constants";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useClickOutside } from "hooks/useClickOutside";
import { useConfig } from "hooks/useConfig";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useKey } from "hooks/useKey";
import { getLocalStorageValue, useUpdateLocalStorage } from "hooks/useLocalStorage";
import { minBy } from "lodash-es";
import { useProjectContext } from "providers/ProjectContext";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

import { GraphView } from "./components/GraphView";
import { ListView } from "./components/ListView";
import { TilesView } from "./components/TilesView";

const localStorageKey = "portfolio-view";

export function Overview(): React.ReactNode {
  const { t } = useTranslation();
  const rootRef = useRef<HTMLDivElement>(null);
  const projectSwitcherRef = useRef<HTMLDivElement>(null);
  const api = useApi();
  const sessionUser = useSessionUser();
  const { changeProject } = useProjectContext();
  const navigate = useNavigate();
  const [addProjectModalOpen, addProjectModalOpenHandlers] = useBool();
  const [projectSwitcherOpen, projectSwitcherOpenHandlers] = useBool();
  const [systemSettingsMenuOpen, systemSettingsMenuOpenHandlers] = useBool();
  const [alertsMenuOpen, alertsMenuOpenHandlers] = useBool();
  const [ticketsMenuOpen, ticketsMenuOpenHandlers] = useBool();
  const [view, setView] = useState<"tiles" | "list">(() => getLocalStorageValue(localStorageKey, "tiles"));
  useUpdateLocalStorage(localStorageKey, view);
  const maxDate = useMemo(() => startOfMonth(addMonths(new Date(), -1)), []);
  const [date, setDate] = useState(() => maxDate);
  const dateQuery = format(date, "yyyy-MM-dd");
  const {
    data: portfolio,
    error: portfolioError,
    isPending: isLoadingPortfolio,
  } = useQuery({
    queryKey: QUERY_KEYS.PORTFOLIO_LIST(dateQuery),
    queryFn: () =>
      api.getProjectOverviewV1({
        month: dateQuery,
        Offset: 0,
        Limit: 1000,
      }),
    select: commonAPIDataSelector,
  });
  const { data: benchmark, isPending: isLoadingBenchmark } = useQuery({
    queryKey: QUERY_KEYS.PORTFOLIO_BENCHMARK(dateQuery),
    queryFn: () =>
      api.getProjectOverviewBenchmarkV1({
        month: dateQuery,
      }),
    select: commonAPIDataSelector,
  });

  const { data: canMassMessage, isPending: isLoadingMassMessagePermission } = useQuery({
    queryKey: QUERY_KEYS.MASS_MESSAGES_PERMISSION(),
    queryFn: api.getMassMessagesV1,
    select: commonAPIDataSelector,
  });

  const minDate = useMemo(
    () =>
      portfolio && portfolio.items.length > 0
        ? max([
            new Date(2020, 3), // No analytics exist before this date
            minBy(
              portfolio.items.map((x) => parseISO(x.createdAt)),
              (x) => x.valueOf(),
            )!,
          ])
        : undefined,
    [portfolio],
  );
  const { setting: showEngagement = false } = useConfig("enablePortfolioEngagementStat");

  useClickOutside(projectSwitcherRef, projectSwitcherOpenHandlers.setFalse, projectSwitcherOpen);
  useKey("Escape", projectSwitcherOpenHandlers.setFalse, projectSwitcherOpen);

  useDocumentTitle(t("page.portfolio.title"));

  const actions = useMemo(() => {
    const actions: ContextMenuAction[] = [];

    actions.push({
      text: t("page.portfolio.add-new-project"),
      callback: addProjectModalOpenHandlers.setTrue,
    });

    if (canMassMessage?.canMassMessageGeneralPost || canMassMessage?.canMassMessageAnnouncementPost) {
      actions.push({
        text: t("page.portfolio.create-message-multiple-projects"),
        callback: () => navigate(routes.portfolio.createMassMessage()),
      });
    }

    return actions;
  }, [t, canMassMessage, addProjectModalOpenHandlers.setTrue, navigate]);

  useClickOutside(rootRef, alertsMenuOpenHandlers.setFalse, alertsMenuOpen);
  useKey("Escape", alertsMenuOpenHandlers.setFalse, alertsMenuOpen);

  useClickOutside(rootRef, systemSettingsMenuOpenHandlers.setFalse, systemSettingsMenuOpen);
  useKey("Escape", systemSettingsMenuOpenHandlers.setFalse, systemSettingsMenuOpen);

  useClickOutside(rootRef, ticketsMenuOpenHandlers.setFalse, ticketsMenuOpen);
  useKey("Escape", ticketsMenuOpenHandlers.setFalse, ticketsMenuOpen);

  if (portfolioError) {
    return <ErrorPage error={portfolioError} />;
  }

  if (isLoadingPortfolio || isLoadingBenchmark || isLoadingMassMessagePermission) {
    return <FullSizeLoader withPadding />;
  }

  return (
    <>
      <Modal isOpen={addProjectModalOpen} onRequestClose={addProjectModalOpenHandlers.setFalse} shouldCloseOnEsc>
        <div className="flex max-w-lg flex-col gap-3 px-8 py-12">
          <Headline4 as="h2">{t("page.portfolio.add-new-project.modal.title")}</Headline4>
          <Capture2 as="p">
            <Trans
              i18nKey="page.portfolio.add-new-project.modal.description"
              components={{
                email: (
                  <a className="text-aop-basic-blue" href={`mailto:${SUPPORT_EMAIL}`}>
                    {SUPPORT_EMAIL}
                  </a>
                ),
              }}
            />
          </Capture2>
        </div>
      </Modal>
      <div className="mx-auto max-w-7xl">
        <Paper
          title={t("page.portfolio.header.title", { name: sessionUser.fullName })}
          subTitle={t("page.portfolio.header.description")}
          theme="minimal"
          actions={
            <ContextMenu actions={actions}>
              {(props) => (
                <IconButton
                  styling="primary"
                  title={t("component.context-menu.action.open")}
                  isPressed={props.isOpen}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.openHandlers.toggle();
                  }}
                  isCircular
                >
                  <Icon name={iconPlus} size={24} />
                </IconButton>
              )}
            </ContextMenu>
          }
        >
          <div className="flex flex-col gap-4">
            <div className="flex flex-col justify-between gap-5 md:flex-row md:items-center">
              <Subtitle2 className="mr-auto" as="h2">
                {portfolio ? t("page.portfolio.overview.title", { count: portfolio.total }) : null}
              </Subtitle2>
              <div className="flex flex-wrap items-center gap-x-5 gap-y-2">
                <div className="flex items-center gap-2">
                  <Capture2 className="text-grey-dark">{t("page.portfolio.overview.filters.month")}</Capture2>
                  <MonthPicker value={date} onChange={setDate} minDate={minDate} maxDate={maxDate} placement="left" />
                </div>
                <div className="flex items-center gap-2">
                  <Capture2 className="text-grey-dark">{t("page.portfolio.overview.filters.view")}</Capture2>
                  <Select
                    items={["tiles", "list"] as const}
                    keySelector={(x) => x}
                    renderOption={(x) => (
                      <span className="flex items-center gap-2">
                        {x === "tiles" ? <Icon name={iconGrid01} size={16} /> : <Icon name={iconList} size={16} />}
                        <span>
                          {x === "tiles"
                            ? t("page.portfolio.overview.filters.view.tiles")
                            : t("page.portfolio.overview.filters.view.list")}
                        </span>
                      </span>
                    )}
                    selected={view}
                    onChange={setView}
                  />
                </div>
              </div>
            </div>

            {portfolio ? (
              view === "list" ? (
                <ListView
                  items={portfolio.items}
                  showAddProject={addProjectModalOpenHandlers.setTrue}
                  showEngagement={showEngagement}
                  onChangeProject={(projectId: string) => changeProject(projectId, true)}
                />
              ) : (
                <TilesView
                  items={portfolio.items}
                  onShowAddProject={addProjectModalOpenHandlers.setTrue}
                  showEngagement={showEngagement}
                  onChangeProject={(projectId: string) => changeProject(projectId, true)}
                />
              )
            ) : (
              <FullSizeLoader withPadding />
            )}
          </div>

          {portfolio ? (
            <GraphView month={date} showEngagement={showEngagement} items={portfolio.items} benchmark={benchmark} />
          ) : null}
        </Paper>
      </div>
    </>
  );
}
