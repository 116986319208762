import { useSuspenseQuery } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Select } from "components/Select/Select";
import { Overline2 } from "components/Text/Text";
import { Tooltip } from "components/Tooltip/Tooltip";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { useProjectId } from "hooks/Network/useProjectId";
import { max } from "lodash-es";
import { LineChart } from "modules/analytics/components/LineChart";
import { NoData } from "modules/analytics/components/NoData";
import type { DateRangeOption } from "modules/analytics/util";
import { formatChartDate } from "modules/analytics/util";
import { QUERY_KEYS } from "query-keys";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { NonEmptyArray } from "types/utility-types";

import { Card, CardHeader } from "../../components/Card";

interface Props {
  dateRangeOptions: NonEmptyArray<DateRangeOption>;
}

export function MessageTypesCard({ dateRangeOptions }: Props): React.ReactNode {
  const projectId = useProjectId();
  const { t, i18n } = useTranslation();
  const api = useApi();
  const [dateRange, setDateRange] = useState<DateRangeOption>(dateRangeOptions[0]);
  const { data } = useSuspenseQuery({
    queryKey: QUERY_KEYS.ANALYTICS_MESSAGE_TYPES(projectId, dateRange.value),
    queryFn: () => api.getAnalyticsMessageLabelStatsV1({ dateRange: dateRange.value }),
    select: commonAPIDataSelector,
  });

  const mappedAskHelpData = useMemo(
    () =>
      data?.data.map((x) => ({
        x: formatChartDate(x.range),
        y: x.askHelpCount,
      })) || [],
    [data],
  );

  const mappedInformData = useMemo(
    () =>
      data?.data.map((x) => ({
        x: formatChartDate(x.range),
        y: x.informCount,
      })) || [],
    [data],
  );

  const mappedSellItemData = useMemo(
    () =>
      data?.data.map((x) => ({
        x: formatChartDate(x.range),
        y: x.sellItemCount,
      })) || [],
    [data],
  );

  const noData = !data || data.data.every((x) => x.askHelpCount === 0 && x.informCount === 0 && x.sellItemCount === 0);

  return (
    <Card>
      <CardHeader
        title={t("page.analytics-overview.chart.message-types.title")}
        tooltip={t("page.analytics-overview.chart.message-types.title-tooltip")}
      >
        <Select
          styling="implicit"
          items={dateRangeOptions}
          selected={dateRange}
          onChange={setDateRange}
          renderOption={(x) => x.name}
          keySelector={(x) => x.value}
        />
      </CardHeader>
      {noData ? (
        <NoData />
      ) : (
        <div>
          <LineChart
            data={{
              length: max([mappedAskHelpData.length, mappedInformData.length, mappedSellItemData.length]) || 0,
              data: [
                {
                  dataPoints: mappedAskHelpData,
                  color: "#23173a",
                  label: t("page.analytics-overview.chart.message-types.hover.help"),
                },
                {
                  dataPoints: mappedInformData,
                  color: "#30b0c7",
                  label: t("page.analytics-overview.chart.message-types.hover.inform"),
                },
                {
                  dataPoints: mappedSellItemData,
                  color: "#ff9500",
                  label: t("page.analytics-overview.chart.message-types.hover.sell"),
                },
              ],
            }}
            xLabel={t("page.analytics-overview.chart.message-types.x-axis")}
            yLabel={t("page.analytics-overview.chart.message-types.y-axis")}
            minTopY={5}
            renderLabel={({ x, relatedPoints }) => (
              <>
                <span>{formatDate(i18n, "monthYear", x)}</span>
                <br />
                {relatedPoints.map((point) => (
                  <p className="flex items-center gap-2" key={point.label}>
                    <span className="mt-0.5 size-3 rounded-sm" style={{ backgroundColor: point.color }} />
                    <span className="flex flex-1 justify-between gap-2">
                      <span>{point.label}:</span>
                      <span>{point.dataPoint.y}</span>
                    </span>
                  </p>
                ))}
              </>
            )}
          />
          <div className="mt-8 flex w-full items-start justify-center gap-4">
            <Tooltip tooltip={t("page.analytics-overview.chart.message-types.legend.help.tooltip")}>
              <div className="flex gap-2">
                <div className="mt-0.5 size-3 rounded-sm bg-aop-dark-blue" />
                <div className="flex flex-col gap-2">
                  <Overline2 className="whitespace-pre-line text-black">
                    {t("page.analytics-overview.chart.message-types.legend.help")}
                  </Overline2>
                </div>
              </div>
            </Tooltip>
            <Tooltip tooltip={t("page.analytics-overview.chart.message-types.legend.inform.tooltip")}>
              <div className="flex gap-2">
                <div className="mt-0.5 size-3 rounded-sm bg-teal" />
                <div className="flex flex-col gap-2">
                  <Overline2 className="whitespace-pre-line text-black">
                    {t("page.analytics-overview.chart.message-types.legend.inform")}
                  </Overline2>
                </div>
              </div>
            </Tooltip>
            <Tooltip tooltip={t("page.analytics-overview.chart.message-types.legend.sell.tooltip")}>
              <div className="flex gap-2">
                <div className="mt-0.5 size-3 rounded-sm bg-orange" />
                <div className="flex flex-col gap-2">
                  <Overline2 className="whitespace-pre-line text-black">
                    {t("page.analytics-overview.chart.message-types.legend.sell")}
                  </Overline2>
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      )}
    </Card>
  );
}
