import type {
  CanMassMessageDto,
  CopilotResponseDto,
  MassMessageCreatedEntityDto,
  MessageMultipleProjectsCreateRequest,
  SimpleProjectDto,
  SystemGroupDto,
} from "api/types";
import iconAnnotationAlert from "assets/icons/annotation-alert.svg";
import iconInfo from "assets/icons/info-circle.svg";
import undoIcon from "assets/icons/reverse-left.svg";
import redoIcon from "assets/icons/reverse-right.svg";
import MailManImage from "assets/images/mail-man.svg?react";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { CreatePostAppPreview } from "components/CreatePostAppPreview/CreatePostAppPreview";
import type { DatePickerValue } from "components/DateAndTimePicker/DateAndTimePicker";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormDocumentInput } from "components/Form/FormDocumentInput";
import { FormField } from "components/Form/FormField";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormMultiSelect } from "components/Form/FormMultiSelect";
import { FormScheduleInput } from "components/Form/FormScheduleInput";
import { FormSelect } from "components/Form/FormSelect";
import { FormTextArea } from "components/Form/FormTextArea";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { TipsModal } from "components/MessageTipsModal/MessageTipsModal";
import { Modal } from "components/Modal/Modal";
import { Notice } from "components/Notice/Notice";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Switch } from "components/Switch/Switch";
import { Capture1, Capture2, Headline3, Headline4, Overline2, Subtitle2 } from "components/Text/Text";
import { addHours, addMinutes } from "date-fns";
import { validateSize } from "helpers/file-size";
import { createRequiredStringRule } from "helpers/rules";
import { sortAlphabetically } from "helpers/util";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { GroupIcon } from "modules/community-groups/components/GroupIcons";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

import type { MessageTypes, OptimizeType } from "./Loader";

export interface LayoutProps {
  setSelectedMessageType: (messageType: MessageTypes) => void;
  allowedMassMessageTypes: CanMassMessageDto | undefined;
  setSelectedGroupId: (groupId: string | undefined) => void;
  groups: SystemGroupDto[];
  isLoadingGroups: boolean;
  allowedProjects: SimpleProjectDto[];
  isLoadingProjects: boolean;
  optimizePost: ({
    input,
    option,
  }: {
    input: string;
    option?: Exclude<OptimizeType, "refresh">;
  }) => Promise<CopilotResponseDto>;
  isOptimizingPost: boolean;
  onSubmit: (data: {
    payload: MessageMultipleProjectsCreateRequest;
    images: FormImage[];
    documents: FormDocument[];
  }) => Promise<MassMessageCreatedEntityDto>;
  isSubmitting: boolean;
}

export interface FormValues {
  type: Exclude<MessageMultipleProjectsCreateRequest["type"], "announcementReadOnly">;
  group: SystemGroupDto;
  projects: SimpleProjectDto[];
  scheduled: DatePickerValue;
  title: string;
  body: string;
  images: FormImage[];
  documents: FormDocument[];
}

export function Layout({
  setSelectedMessageType,
  allowedMassMessageTypes,
  setSelectedGroupId,
  groups,
  isLoadingGroups,
  allowedProjects,
  isLoadingProjects,
  optimizePost,
  isOptimizingPost,
  onSubmit,
  isSubmitting,
}: LayoutProps): React.ReactNode {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const sessionUser = useSessionUser();
  const { data: allProjects = [] } = useConnectedProjects();

  const [isTipsModalOpen, tipsModalHandler] = useBool(false);
  const [isConfirmModalOpen, confirmModalHandlers] = useBool(false);
  const [isCancelModalOpen, cancelModalHandler] = useBool(false);
  const [isCommentAllowed, commentAllowedHandler] = useBool(true);
  const [isSuccessModalOpen, successModalHandler] = useBool(false);

  const [originalText, setOriginalText] = useState<string>("");
  const [copilotText, setCopilotText] = useState<string>("");
  const [isCopilotText, setIsCopilotText] = useState<boolean>(false);

  const [audienceReached, setAudienceReached] = useState<MassMessageCreatedEntityDto | null>(null);

  const messageTypes = useMemo<FormValues["type"][]>(() => {
    const types: FormValues["type"][] = [];
    if (allowedMassMessageTypes?.canMassMessageGeneralPost) {
      types.push("undefined");
    }

    if (allowedMassMessageTypes?.canMassMessageAnnouncementPost) {
      types.push("announcement");
    }

    return types;
  }, [allowedMassMessageTypes]);

  const form = useForm<FormValues>({
    defaultValues: {
      title: "",
      body: "",
      scheduled: "",
      images: [],
      documents: [],
    },
  });

  const messageType = useWatch({ control: form.control, name: "type" });
  const group = useWatch({ control: form.control, name: "group" });
  const projects = useWatch({ control: form.control, name: "projects" });
  const messageTitle = useWatch({ control: form.control, name: "title" });
  const messageBody = useWatch({ control: form.control, name: "body" });
  const images = useWatch({ control: form.control, name: "images" });
  const documents = useWatch({ control: form.control, name: "documents" });

  useEffect(() => {
    if (messageType) {
      setSelectedMessageType(messageType);
    }
  }, [messageType, setSelectedMessageType]);

  const minScheduled = useMemo(() => addMinutes(new Date(), 5), []);

  const improve = useCallback(
    async function improve(way?: OptimizeType) {
      if (!isCopilotText) {
        setOriginalText(messageBody);
      }

      const option =
        way === "refresh"
          ? undefined
          : way === "makeShorter"
            ? "makeShorter"
            : way === "makeMorePositive"
              ? "makeMorePositive"
              : "makeUnderstandable";

      const result = await optimizePost({
        input: messageBody,
        option: option,
      });
      setCopilotText(result.generatedText);
      setIsCopilotText(true);
      form.setValue("body", result.generatedText);
    },
    [isCopilotText, optimizePost, messageBody, form],
  );

  function handleFormReset() {
    form.reset();
    setIsCopilotText(false);
    setCopilotText("");
    setOriginalText("");
    setAudienceReached(null);
    successModalHandler.setFalse();
  }

  function addAllProjects() {
    form.setValue("projects", allowedProjects || []);
  }

  function clearProjectSelection() {
    form.resetField("projects");
  }

  function resetBodyToOriginal() {
    setIsCopilotText(false);
    form.setValue("body", originalText);
  }

  function resetBodyToCopilot() {
    setIsCopilotText(true);
    form.setValue("body", copilotText);
  }

  function handleCreate() {
    confirmModalHandlers.setTrue();
  }

  async function handleConfirmedCreate() {
    const formValues = form.getValues();

    const payload: MessageMultipleProjectsCreateRequest = {
      type: formValues.type === "announcement" && !isCommentAllowed ? "announcementReadOnly" : formValues.type,
      systemGroupId: formValues.group ? formValues.group.id : undefined,
      projectSelected: formValues.projects.map((project) => project.id),
      scheduledFor: formValues.scheduled ? formValues.scheduled.toISOString() : undefined,
      title: formValues.title,
      content: formValues.body,
    };

    const res = await onSubmit({
      payload,
      images: formValues.images,
      documents: formValues.documents,
    });

    setAudienceReached(res);
    successModalHandler.setTrue();
  }

  useEffect(() => {
    if (!messageTypes.includes(messageType)) {
      form.setValue("type", messageTypes[0]);
      form.clearErrors("type");
    }
  }, [form, messageTypes, messageType]);

  return (
    <DocumentPaper
      theme="wide"
      title={t("page.create-mass-message.title")}
      subTitle={
        <Breadcrumbs
          pages={[
            {
              name: t("page.portfolio.title"),
              to: routes.portfolio.overview(),
            },
            {
              name: t("page.create-mass-message.breadcrumbs.self"),
            },
          ]}
        />
      }
    >
      <Form formMethods={form} onSubmit={handleCreate}>
        <div className="grid size-full grid-cols-1 gap-4 @6xl:grid-cols-5">
          <div className="flex flex-col gap-4 pr-4">
            <Headline4>{t("page.create-mass-message.section.details")}</Headline4>
            <FormContent maxWidth="xl">
              <FormField label={t("page.create-mass-message.form.type")} required>
                <Overline2 className="block py-2 text-grey-darker">
                  <Trans
                    i18nKey="page.create-mass-message.form.type.description"
                    values={{ count: allowedProjects.length, total: allProjects.length }}
                    components={{
                      b: <b />,
                    }}
                  />
                </Overline2>
                <FormSelect<FormValues, FormValues["type"]>
                  name="type"
                  items={messageTypes}
                  keySelector={(x) => x}
                  renderOption={(x) => {
                    switch (x) {
                      case "undefined":
                        return t("page.create-mass-message.form.type.default");
                      case "announcement":
                        return t("page.create-mass-message.form.type.announcement");
                    }
                  }}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.create-mass-message.form.type"),
                    }),
                  }}
                  onChange={(val) => {
                    setSelectedMessageType(val ? val : messageTypes[0]);
                    clearProjectSelection();
                  }}
                />
              </FormField>
              {groups.length > 0 && (
                <FormField label={t("page.create-mass.message.form.group")}>
                  <FormSelect<FormValues, FormValues["group"]>
                    name="group"
                    items={[
                      ...groups.filter((group) => group.type === "interest"),
                      ...groups.filter((group) => group.type === "helpCategory"),
                    ]}
                    emptyItem={t("page.create-mass-message.form.group.placeholder")}
                    keySelector={(x) => x.id}
                    renderOption={(x) => (
                      <div className="flex items-center gap-2">
                        <GroupIcon icon={x.icon} size={16} />
                        <span>{x.translations?.find((l) => l.languageId === sessionUser.language.id)?.name}</span>
                      </div>
                    )}
                    groupSelector={(x) => {
                      switch (x.type) {
                        case "interest":
                          return t("page.create-mass-message.form.group.type.interest");
                        case "helpCategory":
                          return t("page.create-mass-message.form.group.type.help-category");
                        default:
                          return "";
                      }
                    }}
                    onChange={(val) => {
                      setSelectedGroupId(val ? val.id : undefined);
                      clearProjectSelection();
                    }}
                    disabled={isLoadingGroups}
                  />
                </FormField>
              )}
              {messageType === "announcement" && (
                <Notice
                  icon={iconAnnotationAlert}
                  message={t("page.create-mass-message.form.type.announcement.tooltip")}
                />
              )}
              <FormField label={t("page.create-mass-message.form.projects")} required>
                <Overline2 className="block py-2 text-grey-darker">
                  {t("page.create-mass-message.form.projects.description")}
                </Overline2>
                <FormMultiSelect<FormValues, "projects">
                  name="projects"
                  placeholder={t("page.create-mass-message.form.projects.placeholder")}
                  items={allowedProjects ? allowedProjects : []}
                  sortSelectedItems={sortAlphabetically}
                  keySelector={(x) => x.id}
                  renderOption={(x) => x.name}
                  rules={{
                    required: t("components.form.error.required", {
                      inputName: t("page.create-mass-message.form.projects"),
                    }),
                  }}
                  disabled={isLoadingProjects}
                />
              </FormField>
              <div className="flex w-full flex-wrap gap-2">
                <Button
                  className="w-full"
                  styling="secondary"
                  onClick={addAllProjects}
                  disabled={projects?.length === allowedProjects.length}
                >
                  {t("page.create-mass-message.form.projects.add-all", {
                    count: allowedProjects?.length,
                  })}
                </Button>
                <Button
                  className="w-full"
                  styling="danger"
                  onClick={clearProjectSelection}
                  disabled={!projects || projects.length === 0}
                >
                  {t("page.create-mass-message.form.projects.clear-all")}
                </Button>
              </div>
              <FormField label={t("page.create-mass-message.form.scheduling.title")}>
                <FormScheduleInput
                  name="scheduled"
                  canEdit
                  min={minScheduled}
                  defaultValue={addHours(new Date(), 1)}
                  rules={{
                    validate: {
                      laterThanMin: (date) => {
                        if (!date) {
                          return undefined;
                        }

                        return date < minScheduled
                          ? t("page.message-feed.create.schedule.datetime.error.must-be-in-future")
                          : undefined;
                      },
                    },
                  }}
                />
              </FormField>
            </FormContent>
          </div>
          <div className="flex flex-col gap-4 @6xl:col-span-3">
            <Headline4 className="@6xl:pl-4">{t("page.create-mass-message.section.content")}</Headline4>
            <FormContent className="border-grey-lightest @6xl:border-l @6xl:pl-4" maxWidth="4xl">
              <FormField label={t("page.create-mass-message.form.title")} required>
                <FormInput<FormValues>
                  name="title"
                  placeholder={t("page.create-mass-message.form.title.placeholder")}
                  rules={{
                    validate: {
                      required: createRequiredStringRule(t, "page.create-mass-message.form.title"),
                    },
                  }}
                />
              </FormField>
              <FormField label={t("page.create-mass-message.form.message")} required>
                <FormTextArea<FormValues>
                  name="body"
                  placeholder={t("page.create-mass-message.form.message.placeholder")}
                  rules={{
                    validate: {
                      required: createRequiredStringRule(t, "page.create-mass-message.form.message"),
                    },
                  }}
                  large
                  scrollbar
                  toolbar={
                    <div className="flex h-fit w-full items-center gap-2 overflow-x-auto rounded-t-lg bg-grey-lightest p-2 ring-1 ring-grey-lighter">
                      <Button
                        styling="tertiary"
                        disabled={messageBody.trim().split(" ").length < 3}
                        onClick={() => improve("refresh")}
                        isLoading={isOptimizingPost}
                      >
                        <Capture1>{t("page.create-mass-message.form.message.copilot.generate")}</Capture1>
                      </Button>
                      <Button
                        styling="tertiary"
                        disabled={!isCopilotText}
                        onClick={() => improve("makeShorter")}
                        isLoading={isOptimizingPost}
                      >
                        <Capture1>{t("page.create-mass-message.form.message.copilot.shorten")}</Capture1>
                      </Button>
                      <Button
                        styling="tertiary"
                        disabled={!isCopilotText}
                        onClick={() => improve("makeMorePositive")}
                        isLoading={isOptimizingPost}
                      >
                        <Capture1>{t("page.create-mass-message.form.message.copilot.positive")}</Capture1>
                      </Button>
                      <Button
                        styling="tertiary"
                        disabled={!isCopilotText}
                        onClick={() => improve("makeUnderstandable")}
                        isLoading={isOptimizingPost}
                      >
                        <Capture1>{t("page.create-mass-message.form.message.copilot.simplify")}</Capture1>
                      </Button>
                      <div className="h-8 w-px bg-grey-lighter" />
                      <Button
                        styling="tertiary"
                        disabled={!isCopilotText || isOptimizingPost}
                        onClick={resetBodyToOriginal}
                        className={!isCopilotText || isOptimizingPost ? "fill-grey-dark" : undefined}
                      >
                        <Icon name={undoIcon} size={16} />
                      </Button>
                      <Button
                        styling="tertiary"
                        disabled={isCopilotText || copilotText.length === 0 || isOptimizingPost}
                        onClick={resetBodyToCopilot}
                        className={
                          isCopilotText || copilotText.length === 0 || isOptimizingPost ? "fill-grey-dark" : undefined
                        }
                      >
                        <Icon name={redoIcon} size={16} />
                      </Button>
                      <button
                        className="ml-auto flex items-center gap-3 whitespace-nowrap rounded-md border border-green-darker bg-green-lightest p-2 text-green-darker hover:bg-green-lighter"
                        type="button"
                        onClick={tipsModalHandler.setTrue}
                      >
                        <Icon name={iconInfo} size={16} />
                        <Capture2>{t("page.create-mass-message.form.message.tips")}</Capture2>
                      </button>
                    </div>
                  }
                />
              </FormField>
              {messageType === "announcement" && (
                <FormField label={t("page.create-mass-message.form.commenting")}>
                  <div className="flex items-center gap-2">
                    <Switch
                      size="sm"
                      isChecked={isCommentAllowed}
                      onChange={commentAllowedHandler.toggle}
                      toggleColour="white"
                    />
                    <Subtitle2 className="font-normal">
                      {isCommentAllowed
                        ? t("page.create-mass-message.form.commenting.enabled")
                        : t("page.create-mass-message.form.commenting.disabled")}
                    </Subtitle2>
                  </div>
                </FormField>
              )}
              <FormField label={t("page.create-mass-message.form.file")}>
                <div className="flex gap-2">
                  {!(documents?.length > 0) && (
                    <FormImageInput<FormValues, "images">
                      name="images"
                      texts={{
                        add: t("page.create-mass-message.form.file.image"),
                      }}
                      rules={{
                        validate: {
                          size(image) {
                            if (image) {
                              return validateSize(t, image);
                            }
                          },
                        },
                      }}
                    />
                  )}
                  {!(images?.length > 0) && (
                    <FormDocumentInput<FormValues, "documents">
                      name="documents"
                      accept="application/pdf"
                      withPreview
                      texts={{
                        add: t("page.create-mass-message.form.file.doc"),
                      }}
                      rules={{
                        validate: {
                          size(doc) {
                            if (doc) {
                              return validateSize(t, doc);
                            }
                          },
                        },
                      }}
                    />
                  )}
                </div>
              </FormField>
            </FormContent>
          </div>
          <CreatePostAppPreview
            sectionTitle={t("page.create-mass-message.section.preview")}
            type={messageType}
            title={messageTitle}
            body={messageBody}
            group={group?.translations?.find((l) => l.languageId === sessionUser.language.id)?.name}
            images={images}
            documents={documents}
          />
          <div className="flex gap-4 justify-self-end @6xl:col-span-5">
            <Button styling="secondary" onClick={cancelModalHandler.setTrue} disabled={isSubmitting}>
              {t("common.action.cancel")}
            </Button>
            <Button styling="primary" type="submit" isLoading={isSubmitting}>
              {projects?.length > 10
                ? t("page.create-mass-message.queue-button")
                : t("page.create-mass-message.post-button")}
            </Button>
          </div>
        </div>
      </Form>
      <TipsModal isOpen={isTipsModalOpen} onClose={tipsModalHandler.setFalse} />
      <ConfirmModal
        id="confirm-message-multiple-projects-modal"
        title={t("page.create-mass-message.confirm-modal.title")}
        description={t("page.create-mass-message.confirm-modal.description-count", {
          count: form.getValues("projects")?.length,
        })}
        isLoading={isSubmitting}
        onReject={confirmModalHandlers.setFalse}
        rejectBtnProps={{
          "data-testid": "confirm-message-multiple-projects-modal-cancel",
        }}
        onResolve={async () => {
          await handleConfirmedCreate();
          confirmModalHandlers.setFalse();
        }}
        resolveBtnProps={{
          text: t("common.action.confirm"),
          "data-testid": "confirm-message-multiple-projects-modal-confirm",
        }}
        isOpen={isConfirmModalOpen}
        shouldCloseOnEsc
        data-testid="confirm-message-multiple-projects-modal"
      />
      <ConfirmModal
        id="cancel-modal"
        data-testid="cancel-modal"
        title={t("page.create-mass-message.cancel-modal.title")}
        description={t("page.create-mass-message.cancel-modal.description")}
        isOpen={isCancelModalOpen}
        shouldCloseOnEsc
        onReject={cancelModalHandler.setFalse}
        rejectBtnProps={{
          text: t("common.action.cancel"),
        }}
        onResolve={() => navigate(routes.portfolio.overview())}
        resolveBtnProps={{
          text: t("common.action.confirm"),
        }}
        isLoading={false}
      />
      <Modal isOpen={isSuccessModalOpen} onRequestClose={handleFormReset}>
        <div className="flex flex-col items-center gap-4 px-16 py-8">
          {audienceReached?.minutesExpectedToFinish ? (
            <div className="flex flex-col items-center gap-2">
              <Headline3>
                {t("page.create-mass-message.success-modal.batched.title", {
                  count: Math.ceil(audienceReached.minutesExpectedToFinish / 60),
                })}
              </Headline3>
              {audienceReached.expectedFinishDateTime ? (
                <Subtitle2 className="font-normal">
                  {t("page.create-mass-message.success-modal.batched.subtitle", {
                    date: formatDate(i18n, "datetime", audienceReached.expectedFinishDateTime),
                  })}
                </Subtitle2>
              ) : null}
            </div>
          ) : (
            <Headline3>{t("page.create-mass-message.success-modal.title")}</Headline3>
          )}
          <MailManImage />
          {audienceReached?.projectCount !== undefined ||
          audienceReached?.addressCount !== undefined ||
          audienceReached?.audienceResidentsCount !== undefined ? (
            <div className="flex flex-col items-center gap-1">
              <Subtitle2 className="font-normal">
                {t("page.create-mass-message.success-modal.audience-reached.description.delivered-to")}
              </Subtitle2>
              {audienceReached.projectCount !== undefined && audienceReached.projectCount > 0 && (
                <Subtitle2 className="font-normal">
                  <Trans
                    i18nKey="page.create-mass-message.success-modal.audience-reached.description.projects"
                    values={{
                      projectCount: audienceReached.projectCount,
                    }}
                    count={audienceReached.projectCount}
                    components={{
                      b: <b />,
                    }}
                  />
                </Subtitle2>
              )}
              {audienceReached.addressCount !== undefined && audienceReached.addressCount > 0 && (
                <Subtitle2 className="font-normal">
                  <Trans
                    i18nKey="page.create-mass-message.success-modal.audience-reached.description.addresses"
                    values={{
                      addressCount: audienceReached.addressCount,
                    }}
                    count={audienceReached.addressCount}
                    components={{
                      b: <b />,
                    }}
                  />
                </Subtitle2>
              )}
              {audienceReached.audienceResidentsCount !== undefined ? (
                audienceReached.audienceResidentsCount === 0 ? (
                  <Subtitle2 className="font-normal">
                    <Trans
                      i18nKey="page.create-mass-message.success-modal.audience-reached.description.residents.none"
                      values={{
                        residentCount: audienceReached.audienceResidentsCount,
                      }}
                      components={{
                        b: <b />,
                      }}
                    />
                  </Subtitle2>
                ) : (
                  <Subtitle2 className="font-normal">
                    <Trans
                      i18nKey="page.create-mass-message.success-modal.audience-reached.description.residents"
                      values={{
                        residentCount: audienceReached.audienceResidentsCount,
                      }}
                      count={audienceReached.audienceResidentsCount}
                      components={{
                        b: <b />,
                      }}
                    />
                  </Subtitle2>
                )
              ) : null}
            </div>
          ) : (
            <Subtitle2 className="font-normal">{t("page.create-mass-message.success-modal.description")}</Subtitle2>
          )}
          <div className="flex flex-col items-center gap-4 pt-6">
            <Button styling="tertiary" onClick={handleFormReset}>
              {t("page.create-mass-message.success-modal.button.another-message")}
            </Button>
            <Button styling="primary" onClick={() => navigate(routes.portfolio.overview())}>
              {t("page.create-mass-message.success-modal.button.done")}
            </Button>
          </div>
        </div>
      </Modal>
    </DocumentPaper>
  );
}
