import iconAlertTriangle from "assets/icons/alert-triangle.svg";
import iconDotsGrid from "assets/icons/dots-grid.svg";
import iconMessageSquare02 from "assets/icons/message-square-02.svg";
import iconSettings01 from "assets/icons/settings-01.svg";
import iconSwitchHorizontal01 from "assets/icons/switch-horizontal-01.svg";
import iconTool02 from "assets/icons/tool-02.svg";
import iconUsers01 from "assets/icons/users-01.svg";
import logoDark from "assets/images/logo-dark.svg";
import { Icon } from "components/Icon/Icon";
import { NavigationSidebarUserMenu } from "components/NavigationSidebar/NavigationSidebarUserMenu";
import { Sidebar } from "components/Sidebar/Sidebar";
import type { SidebarBaseItemType, SidebarBodyRootProps, SidebarGroupedItemType } from "components/Sidebar/types";
import { useConnectedProjects } from "hooks/Network/useConnectedProjects";
import { useConfig } from "hooks/useConfig";
import { useScreenIsBiggerThan } from "hooks/useScreenIsBiggerThan";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";

import { PortfolioSidebarProjectsSection } from "./PortfolioSidebarProjectsSection";

interface PortfolioSidebarProps extends Omit<SidebarBodyRootProps, "children"> {}

export function PortfolioSidebar({
  isOpened,
  isCollapsed,
  onToggleOpen,
  onToggleCollapse,
  isFaded,
}: PortfolioSidebarProps): React.ReactNode {
  const { t } = useTranslation();
  const { setting: showAvailableIntegrations } = useConfig("showAvailableIntegrations");
  const connectedProjects = useConnectedProjects();
  const isDesktop = useScreenIsBiggerThan("md");

  const showAlerts =
    connectedProjects.data?.some((x) => x.userRole !== "resident" && x.userRole !== "maintenance") || false;

  const items: (SidebarBaseItemType | SidebarGroupedItemType)[] = [
    {
      label: t("navigation.portfolio.portfolio"),
      icon: <Icon name={iconDotsGrid} />,
      to: routes.portfolio.overview(),
      exact: true,
    },
    {
      label: t("navigation.portfolio.mass-message"),
      icon: <Icon name={iconMessageSquare02} />,
      to: routes.portfolio.createMassMessage(),
      permission: () => showAlerts,
    },
    {
      label: t("navigation.portfolio.tickets"),
      icon: <Icon name={iconTool02} />,
      to: routes.portfolio.tickets(),
      permission: () => connectedProjects.data?.some((x) => x.userRole !== "resident") || false,
    },
    {
      label: t("navigation.portfolio.alerts"),
      icon: <Icon name={iconAlertTriangle} />,
      to: routes.alerts.list(),
      permission: () => showAlerts,
    },
    {
      label: t("navigation.portfolio.user-search"),
      icon: <Icon name={iconUsers01} />,
      to: routes.portfolio.userLookup(),
      permission: (x) => x.isSuperAdmin,
    },
    {
      label: t("navigation.portfolio.integrations"),
      icon: <Icon name={iconSwitchHorizontal01} />,
      to: routes.portfolio.integrations(),
      permission: () => !!showAvailableIntegrations,
    },
    {
      label: t("navigation.portfolio.system-settings"),
      icon: <Icon name={iconSettings01} />,
      permission: (x) => x.isSuperAdmin,
      type: "group",
      items: [
        {
          label: t("navigation.portfolio.project-connections"),
          to: routes.projectConnections.list(),
        },
        {
          label: t("navigation.portfolio.platform-groups"),
          to: routes.platformGroups.list(),
        },
        {
          label: t("navigation.portfolio.automated-surveys"),
          to: routes.automatedSurveys.list(),
        },
        {
          label: t("navigation.portfolio.new-project"),
          to: routes.portfolio.newProject(),
        },
      ],
    },
  ];

  return (
    <Sidebar.Root {...{ isOpened, isCollapsed, onToggleCollapse, onToggleOpen, isFaded }}>
      <Sidebar.Header>
        <div className={twJoin("flex w-fit items-center justify-start md:h-10", isCollapsed ? "px-2" : "px-8")}>
          <img
            src={logoDark}
            className={twJoin("block transition-[width] duration-200", isCollapsed ? "w-10" : "w-16")}
            alt="Area of People logo"
          />
        </div>
      </Sidebar.Header>
      <Sidebar.Body isFirstItemImportant {...{ items }}>
        {isDesktop && (
          <>
            <div className="my-4 flex h-10 w-full items-center px-2">
              <hr className={twJoin("w-full border-grey-lightest")} />
            </div>
            <PortfolioSidebarProjectsSection />
          </>
        )}
      </Sidebar.Body>
      {!isFaded && (
        <Sidebar.Footer>
          <NavigationSidebarUserMenu isPortfolio />
        </Sidebar.Footer>
      )}
    </Sidebar.Root>
  );
}
