import type { AssetBookingDetailDto } from "api/types";
import iconCalendar from "assets/icons/calendar.svg";
import { Breadcrumbs } from "components/Breadcrumbs/Breadcrumbs";
import { Button } from "components/Button/Button";
import { Carousel } from "components/Carousel/Carousel";
import type { ContextMenuAction } from "components/ContextMenu/ContextMenu";
import { ContextMenu } from "components/ContextMenu/ContextMenu";
import { DeleteModal, useDeleteModal } from "components/DeleteModal/DeleteModal";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { Icon } from "components/Icon/Icon";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Pdf } from "components/Pdf/Pdf";
import { Capture2, Headline4, Subtitle2 } from "components/Text/Text";
import { format, parse } from "date-fns";
import { usePermission } from "hooks/usePermission";
import { useSlug } from "hooks/useSlug";
import { Specification } from "modules/bookings/components/Specification";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "routes";

export interface LayoutProps {
  reservation: AssetBookingDetailDto;
  onDelete: () => Promise<void>;
}

export function Layout({ reservation, onDelete }: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const hasPermission = usePermission();

  const { componentProps: deleteModalProps, openDeleteModal } = useDeleteModal<string>("delete-reservation-modal");

  const actions: ContextMenuAction[] = useMemo(() => {
    const actions = [];
    if (reservation.canEdit && !reservation.cancelledAt) {
      actions.push({
        text: t("page.bookings.reservations.actions.edit"),
        callback: () => navigate(routes.bookings.editBooking({ slug, aid: reservation.asset.id, bid: reservation.id })),
      });
    }
    if (reservation.canDelete && !reservation.cancelledAt) {
      actions.push({
        text: t("page.bookings.reservations.actions.cancel"),
        callback: () => openDeleteModal(reservation.id),
      });
    }

    return actions;
  }, [t, navigate, reservation, slug, openDeleteModal]);

  function getCancellationReason(): string {
    switch (reservation.cancellationReason) {
      case "cancelled":
        return reservation.cancellationReasonDescription ?? t("page.bookings.reservation-details.cancelled.cancelled");
      case "assetDeleted":
        return t("page.bookings.reservation-details.cancelled.deleted");
      case "assetUnpublished":
        return t("page.bookings.reservation-details.cancelled.unpublished");
      case "assetUpdated":
        return t("page.bookings.reservation-details.cancelled.updated");
      default:
        return "";
    }
  }

  return (
    <DocumentPaper
      theme="minimal"
      title={t("page.bookings.reservation-details.title")}
      subTitle={
        <Breadcrumbs
          pages={[
            hasPermission((x) => x.assets.canViewSchedule)
              ? {
                  name: t("page.bookings.reservation-details.breadcrumbs.my-reservations.title"),
                  to: routes.reservations.list({ slug }),
                }
              : {
                  name: t("page.bookings.reservation-details.breadcrumbs.calendar.title"),
                  to: routes.reservations.list({ slug }),
                },
            {
              name: t("page.bookings.reservation-details.breadcrumbs.reservation.title"),
            },
          ]}
        />
      }
    >
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="h-fit rounded-lg">
          <div className="h-52 w-full md:h-72 lg:h-96">
            <Carousel images={reservation.asset.images} rounded="top" />
          </div>
          <div className="flex flex-col gap-4 rounded-b-lg bg-white p-4">
            {reservation.cancelledAt ? (
              <div className="flex justify-between">
                <div className="flex flex-col gap-2">
                  <div className="w-fit rounded-lg bg-red-lightest px-2 py-1">
                    <Capture2 className="font-semibold text-red">
                      {t("page.bookings.reservations.reservation-cancelled")}
                    </Capture2>
                  </div>
                  <Subtitle2 className="font-normal">{getCancellationReason()}</Subtitle2>
                </div>
                <ContextMenu actions={actions} />
              </div>
            ) : null}
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                <Icon name={iconCalendar} className="text-grey-dark" />
                <Capture2>{`${format(parse(reservation.date, "yyyy-mm-dd", new Date()), "dd")} ${formatDate(i18n, "month", parse(reservation.date, "yyyy-MM-dd", new Date()))} ${format(parse(reservation.startTime, "HH:mm:ss", new Date()), "HH:mm")} - ${format(parse(reservation.endTime, "HH:mm:ss", new Date()), "HH:mm")}`}</Capture2>
              </div>
              {!reservation.cancelledAt ? <ContextMenu actions={actions} /> : null}
            </div>
            <Headline4>{reservation.asset.name}</Headline4>
            <div className="flex items-center gap-4">
              <Specification specification="locationSpecification" value={reservation.asset.locationSpecification} />
            </div>
            {!reservation.asset.deletedAt ? (
              <Button
                type="link"
                styling="tertiary"
                className="text-aop-basic-blue"
                href={routes.bookings.bookAsset({ slug, aid: reservation.asset.id })}
              >
                {t("page.bookings.reservation-details.asset-details")}
              </Button>
            ) : null}
            {reservation.bookingReason ? (
              <div className="flex flex-col gap-2">
                <Subtitle2>{t("page.bookings.reservation-details.reason")}</Subtitle2>
                <Subtitle2 className="font-normal">{reservation.bookingReason}</Subtitle2>
              </div>
            ) : null}
            {reservation.regulationDocument ? (
              <div className="flex w-fit flex-col gap-2">
                <Subtitle2>{t("page.bookings.reservation-details.regulations")}</Subtitle2>
                <Pdf
                  fileName={reservation.regulationDocument?.fileName}
                  onClick={() => window.open(reservation.regulationDocument?.url, "_blank")}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <DeleteModal
        title={t("page.bookings.reservation-details.delete.modal.title")}
        description={t("page.bookings.reservation-details.delete.modal.description")}
        onDelete={onDelete}
        rejectBtnProps={{
          text: t("page.bookings.reservation-details.delete.modal.keep-reservation"),
          "data-testid": "modal-cancel-delete",
        }}
        deleteBtnProps={{
          text: t("page.bookings.reservation-details.delete.modal.cancel-reservation"),
          "data-testid": "modal-confirm-delete",
        }}
        {...deleteModalProps}
      />
    </DocumentPaper>
  );
}
