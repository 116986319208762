import iconAlertCircle from "assets/icons/alert-circle.svg";
import iconCheck from "assets/icons/check.svg";
import iconChevronDown from "assets/icons/chevron-down.svg";
import iconChevronUp from "assets/icons/chevron-up.svg";
import { Icon } from "components/Icon/Icon";
import { Capture1 } from "components/Text/Text";
import { useSelect } from "downshift";
import { twResolve } from "helpers/tw-resolve";
import { useBool } from "hooks/useBool";
import { groupBy } from "lodash-es";
import type { ReactNode, Ref } from "react";
import { Fragment, useMemo } from "react";
import { twJoin } from "tailwind-merge";

type SelectStyling = "default" | "implicit";

export interface SelectProps<
  T,
  TSelected extends T | undefined = T | undefined,
  TEmptyItem extends React.ReactNode | undefined = React.ReactNode | undefined,
> {
  id?: string;
  placeholder?: ReactNode;
  selected: TSelected;
  items: readonly T[];
  renderOption: (item: T, selected: boolean) => ReactNode;
  renderSelected?: (item: T) => React.ReactNode;
  keySelector: (item: T) => string | number;
  groupSelector?: (item: T) => string;
  emptyItem?: TEmptyItem;
  styling?: SelectStyling;
  disabled?: boolean;
  onChange?: (item: TEmptyItem extends undefined | null ? T : T | undefined) => void;
  "data-testid"?: string;
  "aria-invalid"?: boolean;
  onBlur?: () => void;
  hideChevron?: boolean;
  autoFocus?: boolean;
  isRightAligned?: boolean;
  className?: string;
  ref?: Ref<HTMLButtonElement>;
}

export function Select<
  T,
  TSelected extends T | undefined = T | undefined,
  TEmptyItem extends React.ReactNode | undefined = undefined,
>({
  selected,
  disabled,
  placeholder,
  items,
  renderOption,
  renderSelected = (x) => renderOption(x, false),
  keySelector,
  groupSelector,
  onChange,
  emptyItem,
  styling = "default",
  "aria-invalid": isInvalid,
  onBlur,
  hideChevron,
  id,
  ref,
  autoFocus,
  isRightAligned,
  className,
  ...props
}: SelectProps<T, TSelected, TEmptyItem>): React.ReactNode {
  const [isClearSelectionHovered, clearSelectionHoverHandlers] = useBool(false);
  const [showHighlight, showHighlightHandlers] = useBool();
  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps, highlightedIndex } = useSelect<T | null>({
    items: [...(emptyItem == null ? [] : [null]), ...items],
    selectedItem: selected ?? null,
    onSelectedItemChange(changes) {
      if (emptyItem == null && !changes.selectedItem) {
        return;
      }

      onChange?.((changes.selectedItem ?? undefined) as T);
    },
    onHighlightedIndexChange() {
      showHighlightHandlers.setTrue();
    },
    onIsOpenChange(x) {
      if (x.isOpen) {
        showHighlightHandlers.setFalse();
      } else {
        onBlur?.();
      }
    },
    defaultIsOpen: autoFocus,
  });

  const styles = getStyling(styling);

  const orderedGroups = useMemo(() => {
    const itemsWithIndex = items.map((item, index) => ({ item, index }));
    if (groupSelector) {
      return Object.entries(groupBy(itemsWithIndex, (x) => groupSelector(x.item)));
    }

    return [["", items.map((item, index) => ({ item, index }))] as [string, readonly { item: T; index: number }[]]];
  }, [items, groupSelector]);

  return (
    <div
      data-cy-is-selected={!!selected}
      data-testid="select"
      {...props}
      className={twResolve("group relative", isOpen && "z-20", disabled && "pointer-events-none", className)}
    >
      <button
        type="button"
        {...getToggleButtonProps({ ref })}
        disabled={disabled}
        className={twResolve(
          "relative h-10 w-full cursor-default rounded-lg py-1 pl-2 pr-10 text-left focus:outline-none focus-visible:ring-1 focus-visible:ring-grey-darkest",
          styles.button,
          isOpen && styles.buttonIsOpen,
          isInvalid && "ring-2 ring-red-dark",
        )}
        data-testid="select-button"
        id={id}
      >
        <div
          className={twJoin("flex items-center justify-between truncate", !selected ? styles.contentIsNull : undefined)}
          data-testid="select-placeholder"
          data-value={selected ? keySelector(selected as T) : undefined}
        >
          {selected ? renderSelected(selected as T) : placeholder || emptyItem}
          {isInvalid && <Icon name={iconAlertCircle} size={16} />}
        </div>
        <span
          className={twResolve(
            "pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2",
            styles.chevron,
            hideChevron && "opacity-100 group-hocus:opacity-100 sm:opacity-0",
            isOpen && "sm:opacity-100",
            isInvalid && "text-red-dark",
          )}
        >
          {isOpen ? <Icon name={iconChevronUp} /> : <Icon name={iconChevronDown} />}
        </span>
      </button>
      <div
        {...getMenuProps()}
        aria-invalid={isInvalid}
        className={twResolve(
          "absolute flex max-h-96 w-max min-w-full flex-col items-start overflow-auto rounded-lg border bg-white shadow-md !outline-none",
          styles.list,
          isOpen ? "opacity-100" : "opacity-0",
          isRightAligned ? "right-0" : undefined,
        )}
      >
        {isOpen ? (
          <>
            {emptyItem != null && (
              <div
                key="__empty"
                {...getItemProps({ index: 0, item: null })}
                onMouseEnter={clearSelectionHoverHandlers.setTrue}
                className={twJoin(
                  "w-full cursor-pointer select-none px-3 py-1 text-left italic text-black !outline-none first:mt-2 last:mb-2",
                  isOpen && showHighlight && isClearSelectionHovered && highlightedIndex === 0 && "bg-blue-lightest",
                )}
                onMouseLeave={clearSelectionHoverHandlers.setFalse}
              >
                {emptyItem || "-"}
              </div>
            )}
            {orderedGroups.map(([groupTitle, groupItems]) => (
              <Fragment key={groupTitle}>
                {groupTitle && orderedGroups.length > 1 ? (
                  <Capture1 className="px-1 pb-1 pt-3">{groupTitle}</Capture1>
                ) : null}
                {groupItems.map(({ item, index }) => {
                  if (emptyItem != null) {
                    index++;
                  }

                  const key = keySelector(item);
                  const isChecked = selected ? keySelector(selected) === key : false;

                  const { key: groupItemKey, ...groupItemProps } = getItemProps({ key, index, item });

                  return (
                    <div
                      key={groupItemKey}
                      {...groupItemProps}
                      aria-checked={isChecked}
                      className={twJoin(
                        "w-full cursor-pointer select-none px-3 py-1 text-black !outline-none first:mt-2 last:mb-2",
                        isOpen &&
                          showHighlight &&
                          !isClearSelectionHovered &&
                          highlightedIndex === index &&
                          "bg-blue-lightest",
                      )}
                      data-testid="select-item"
                      data-value={key}
                    >
                      <div
                        className={twJoin(
                          "flex items-center justify-between gap-2",
                          isChecked ? "font-semibold" : undefined,
                        )}
                      >
                        {renderOption(item, isChecked)}
                        {isChecked ? <Icon name={iconCheck} /> : null}
                      </div>
                    </div>
                  );
                })}
              </Fragment>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
}

function getStyling(styling: SelectStyling) {
  switch (styling) {
    case "default":
      return {
        button:
          "bg-white border border-grey-lighter leading-[26px] hover:border-grey-darker disabled:bg-grey-lightest disabled:text-grey-light disabled:border-grey-lighter",
        buttonIsOpen: "border-grey-darker rounded-b-none hocus:border-grey-darker",
        contentIsNull: "text-grey-light",
        chevron: "text-grey-darker group-hover:text-grey-darkest",
        list: "-mt-px rounded-t-none border-grey-darker",
      };
    case "implicit":
      return {
        button: "bg-grey-lightest sm:bg-white hover:bg-grey-lightest disabled:opacity-50",
        buttonIsOpen: undefined,
        contentIsNull: "text-grey-dark",
        chevron: "text-grey-light group-hover:text-grey",
        list: "mt-1 -ml-1 border-grey-light focus-visible:ring-1 focus-visible:ring-grey-lighter",
      };
  }
}
