import type { ReminderStatusDto, SimpleProjectDto, UserDetailsWithAddressInterestsAndHelpDto } from "api/types";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import { formatDate } from "components/FormattedDate/FormattedDate";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Capture1, Capture2, Subtitle2 } from "components/Text/Text";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { UserNameLink } from "components/UserNameLink/UserNameLink";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface UserDetailProps {
  user: UserDetailsWithAddressInterestsAndHelpDto;
  deletedBy?: UserDetailsWithAddressInterestsAndHelpDto;
  projects: SimpleProjectDto[];
  reminderStatus?: ReminderStatusDto;
  sendReminder: () => void;
}

export function UserDetails({
  user: {
    id: userId,
    avatar,
    deletedAt,
    fullName,
    locatedAt,
    addressTags,
    createdAt,
    registeredAt,
    email,
    phoneNumber,
    occupation,
    biography,
    interestGroups,
    helpCategories,
    isAdmin,
    deleteAfter,
    project,
  },
  deletedBy,
  reminderStatus,
  sendReminder,
  projects,
}: UserDetailProps): React.ReactNode {
  const sessionUser = useSessionUser();
  const { t, i18n } = useTranslation();
  const [isInviteModalOpen, inviteModalHandler] = useBool(false);
  const hasPermission = usePermission();

  const invitesTooltip = useMemo(
    () =>
      reminderStatus?.reminders
        ? reminderStatus.reminders
            .map((reminder) =>
              t("page.user-detail.invite-user.tooltip", { date: formatDate(i18n, "date", reminder.lastReminded) }),
            )
            .join("\n")
        : undefined,
    [reminderStatus?.reminders, t, i18n],
  );

  const building = useMemo(() => addressTags.find((tag) => tag.type === "building")?.key, [addressTags]);

  function handleSendReminder() {
    sendReminder();
    inviteModalHandler.setFalse();
  }

  const showReminderButton =
    !registeredAt &&
    (sessionUser.isSuperAdmin || reminderStatus?.canBeReminded || reminderStatus?.recentlyInvited) &&
    ((isAdmin && hasPermission((x) => x.userManagement.canEditAdminInfo)) ||
      (!isAdmin && hasPermission((x) => x.userManagement.canEditResidentInfo)));

  return (
    <>
      <div className="flex w-full flex-col gap-5 rounded-lg bg-white px-5 py-10 lg:w-96">
        <div className="size-24 self-center">
          <UserAvatar img={avatar} isUserDeleted={!!deletedAt} big />
        </div>
        <div className="flex flex-col items-center gap-0.5">
          <Subtitle2 className="text-center" data-testid="user-fullname">
            {fullName}
          </Subtitle2>
          <Capture2 className="line-clamp-2 text-center text-subtitle2 leading-subtitle2" data-testid="user-address">
            {locatedAt}
          </Capture2>
          <Capture2 className="text-center text-subtitle2 leading-subtitle2" data-testid="user-building">
            {building && project
              ? t("page.user-detail.building", { building: building, project: project.name })
              : project
                ? project.name
                : building}
          </Capture2>
        </div>
        <div className="mb-5 flex flex-col items-center gap-0.5">
          {!!createdAt && (
            <Capture2 className="text-grey">
              {t("page.user-detail.created", { date: formatDate(i18n, "date", createdAt) })}
            </Capture2>
          )}
          {!!registeredAt && (
            <Capture2 className="text-grey">
              {t("page.user-detail.registered", { date: formatDate(i18n, "date", registeredAt) })}
            </Capture2>
          )}
          {!!deleteAfter && !deletedAt && (
            <Capture2 className="text-grey">
              {t("page.user-detail.deleted-after", { date: formatDate(i18n, "date", deleteAfter) })}
            </Capture2>
          )}
          {showReminderButton && (
            <>
              <Capture2 className="text-grey">{t("page.user-detail.not-registered")}</Capture2>
              <Button
                className="mx-auto mt-4"
                styling="primary"
                title={
                  reminderStatus?.recentlyInvited
                    ? `${invitesTooltip}\n\n${t("page.user-detail.invite-user.recently-invited")}`
                    : invitesTooltip
                }
                onClick={inviteModalHandler.setTrue}
                disabled={
                  !reminderStatus?.canBeReminded || (!sessionUser.isSuperAdmin && reminderStatus?.recentlyInvited)
                }
              >
                {t("page.user-detail.invite-user.button")}
              </Button>
            </>
          )}
          {!!deletedAt && (
            <>
              <Capture2 className="text-grey">
                {t("page.user-detail.deleted", { date: formatDate(i18n, "date", deletedAt) })}
                {deletedBy ? (
                  <>
                    {" "}
                    {t("page.user-detail.deleted-by")}{" "}
                    <span className="underline hover:text-grey-darker">
                      {deletedBy.id === userId ? (
                        t("page.user-detail.deleted-by-themselves")
                      ) : (
                        <UserNameLink user={deletedBy}>{deletedBy.fullName}</UserNameLink>
                      )}
                    </span>
                  </>
                ) : null}
              </Capture2>
            </>
          )}
        </div>
        {(!!email || !!phoneNumber) && (
          <div className="flex flex-col gap-0.5">
            <Capture1 className="mb-2 border-b-[0.5px] border-grey-lightest pb-2">
              {t("page.user-detail.contact-details")}
            </Capture1>
            <Capture2>{email}</Capture2>
            <Capture2>{phoneNumber}</Capture2>
          </div>
        )}
        {!!occupation && (
          <div className="flex flex-col gap-0.5">
            <Capture1 className="mb-2 border-b-[0.5px] border-grey-lightest pb-2">
              {t("page.user-detail.occupation")}
            </Capture1>
            <Capture2>{occupation}</Capture2>
          </div>
        )}
        {!!biography && (
          <div className="flex flex-col gap-0.5">
            <Capture1 className="mb-2 border-b-[0.5px] border-grey-lightest pb-2">
              {t("page.user-detail.biography")}
            </Capture1>
            <Capture2>
              <LinkFormatter>{biography}</LinkFormatter>
            </Capture2>
          </div>
        )}
        {interestGroups?.length > 0 && (
          <div className="flex flex-col gap-0.5">
            <Capture1 className="mb-2 border-b-[0.5px] border-grey-lightest pb-2">
              {t("page.user-detail.groups")}
            </Capture1>
            <ul>
              {interestGroups.map((item) => (
                <li key={item.id}>
                  <Capture2 key={item.id}>{item.name}</Capture2>
                </li>
              ))}
            </ul>
          </div>
        )}
        {helpCategories?.length > 0 && (
          <div className="flex flex-col gap-0.5">
            <Capture1 className="mb-2 border-b-[0.5px] border-grey-lightest pb-2">
              {t("page.user-detail.help-categories")}
            </Capture1>
            <ul>
              {helpCategories.map((item) => (
                <li key={item.id}>
                  <Capture2>{item.name}</Capture2>
                </li>
              ))}
            </ul>
          </div>
        )}
        {projects?.length > 0 && sessionUser.isSuperAdmin && (
          <div className="flex flex-col gap-0.5">
            <Capture1 className="mb-2 border-b-[0.5px] border-grey-lightest pb-2">
              {t("page.user-detail.connected-projects")}
            </Capture1>
            <ul>
              {projects.map((item) => (
                <li key={item.id}>
                  <Capture2>{item.name}</Capture2>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <ConfirmModal
        id="invite-modal"
        isLoading={false}
        isOpen={isInviteModalOpen}
        onReject={inviteModalHandler.setFalse}
        onResolve={handleSendReminder}
        resolveBtnProps={{
          text: t("page.user-detail.invite-user-modal.confirm"),
        }}
        title={t("page.user-detail.invite-user-modal.title")}
        shouldCloseOnEsc
      >
        <div className="flex flex-col gap-2 text-grey-dark">
          <Capture2>
            {t("page.user-detail.invite-user-modal.reminder-num", { count: reminderStatus?.reminders.length })}
          </Capture2>
          <Capture2>
            {t("page.user-detail.invite-user-modal.last-reminder", {
              date: reminderStatus?.reminders.length
                ? formatDate(i18n, "date", reminderStatus.reminders[reminderStatus.reminders.length - 1].lastReminded)
                : "-",
            })}
          </Capture2>
        </div>
      </ConfirmModal>
    </>
  );
}
