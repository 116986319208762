import type { MessageV2Dto } from "api/types";
import iconSettings01 from "assets/icons/settings-01.svg";
import { Anchor } from "components/Anchor/Anchor";
import { IconButton } from "components/Button/IconButton";
import { Icon } from "components/Icon/Icon";
import { LoadingIcon } from "components/Icons/Icons";
import { Modal } from "components/Modal/Modal";
import { Paper } from "components/Paper/Paper";
import { Subtitle2 } from "components/Text/Text";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useBool } from "hooks/useBool";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { useSlug } from "hooks/useSlug";
import { CommunityPost } from "modules/messages/pages/List/components/CommunityPost";
import { useTranslation } from "react-i18next";
import { routes } from "routes";

import {
  ContributionWidget,
  DataWidgetModal,
  EngagementWidget,
  TicketRatingWidget,
  TicketSolvingTimeWidget,
} from "../components/DataWidgets";
import { HomeFeelingAtHomeWidget } from "../components/HomeFeelingAtHomeWidget";
import type { WidgetTypes } from "../constants";

export interface LayoutProps {
  posts: MessageV2Dto[];
  isLoadingPosts: boolean;
  canViewWidgets: boolean;
  widgets: WidgetTypes[];
  isLoadingWidgets: boolean;
}

const POST_LIMIT = 5;

export function Layout({
  posts,
  isLoadingPosts,
  canViewWidgets,
  widgets,
  isLoadingWidgets,
}: LayoutProps): React.ReactNode {
  const slug = useSlug();
  const sessionUser = useSessionUser();
  const { t } = useTranslation();
  const [isWidgetModalOpen, widgetModalHandler] = useBool(false);

  useDocumentTitle(undefined);

  return (
    <Paper theme="no-header-minimal">
      <div className="inline-grid max-w-4xl grid-cols-1 gap-8 @xl:w-full @6xl:max-w-none @6xl:grid-cols-[auto_auto] @6xl:justify-between">
        {sessionUser.isAdmin ? <HomeFeelingAtHomeWidget /> : null}
        {canViewWidgets ? (
          <div className="flex flex-col gap-4 justify-self-stretch">
            {isLoadingWidgets ? (
              <div className="mt-20 flex min-w-72 max-w-full justify-around">
                <LoadingIcon className="w-8" />
              </div>
            ) : (
              <>
                <div className="flex flex-row-reverse items-center justify-between">
                  <IconButton
                    className="justify-self-end"
                    title={t("page.home.data-widgets.button.tooltip")}
                    onClick={widgetModalHandler.setTrue}
                  >
                    <Icon name={iconSettings01} size={16} />
                  </IconButton>
                  {widgets.length > 0 && <Subtitle2>{t("page.home.data-widgets.title")}</Subtitle2>}
                </div>
                {widgets.includes("ticketRating") && <TicketRatingWidget />}
                {widgets.includes("ticketSolvingTime") && <TicketSolvingTimeWidget />}
                {widgets.includes("engagement") && <EngagementWidget />}
                {widgets.includes("contribution") && <ContributionWidget />}
              </>
            )}
          </div>
        ) : null}
        {isLoadingPosts ? (
          <div className="mt-20 flex w-full justify-around">
            <LoadingIcon className="w-8" />
          </div>
        ) : (
          posts?.length > 0 && (
            <div className="flex">
              <div className="flex w-0 grow flex-col gap-4">
                <div className="flex items-center justify-between gap-2">
                  <Subtitle2>{t("page.home.message-feed", { count: POST_LIMIT })}</Subtitle2>
                  <span className="whitespace-nowrap">
                    <Anchor to={routes.messageFeed.list({ slug })}>{t("page.home.message-feed-link")}</Anchor>
                  </span>
                </div>
                <div className="flex flex-col gap-5">
                  {posts.map((post) => (
                    <CommunityPost key={post.id} message={post} isExpanded={false} />
                  ))}
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <Modal isOpen={isWidgetModalOpen} onRequestClose={widgetModalHandler.setFalse} shouldCloseOnEsc>
        <DataWidgetModal onClose={widgetModalHandler.setFalse} activeWidgets={widgets} />
      </Modal>
    </Paper>
  );
}
