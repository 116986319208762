import binocularsIcon from "assets/icons/aop/binocular.svg";
import iconCheck from "assets/icons/check.svg";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { Select } from "components/Select/Select";
import { Switch } from "components/Switch/Switch";
import { Capture2 } from "components/Text/Text";
import { Tooltip } from "components/Tooltip/Tooltip";
import { Trans, useTranslation } from "react-i18next";

import type { TicketSorting, TicketTabs } from "../constants";
import { IN_PROGRESS_TICKET_TAB, REMINDER_TICKET_TAB } from "../constants";

interface TicketTableActionsProps {
  results?: number;
  total?: number;
  onUpdateFocus: (val: boolean) => void;
  sorting: TicketSorting;
  onSort: (sorting: TicketSorting) => void;
  onMarkAsRead: () => void;
  tab: TicketTabs;
  isFocusModeOn: boolean;
  disableSorting?: boolean;
}

export function TicketTableActions({
  results,
  total,
  onUpdateFocus,
  sorting,
  onSort,
  tab,
  isFocusModeOn,
  disableSorting,
  onMarkAsRead,
}: TicketTableActionsProps): React.ReactNode {
  const { t } = useTranslation();

  const sortingOptions: { value: TicketSorting; label: string }[] = [
    { value: "oldest", label: t("page.tickets.table.actions.sort.option.older-first") },
    { value: "newest", label: t("page.tickets.table.actions.sort.option.newer-first") },
    { value: "leastRecentActivity", label: t("page.tickets.table.actions.sort.option.least-recent-activity") },
    { value: "mostRecentActivity", label: t("page.tickets.table.actions.sort.option.most-recent-activity") },
  ];

  return (
    <div className="flex flex-wrap items-center justify-between gap-4 px-6 py-4">
      <Capture2>
        {results != null && total != null ? (
          <Trans
            i18nKey="page.tickets.table.actions.results.label"
            values={{
              count: results,
              total: total,
            }}
            components={{
              bold: <b />,
            }}
          />
        ) : null}
      </Capture2>
      <div className="flex flex-wrap items-center gap-4 xs:ml-auto">
        {tab === IN_PROGRESS_TICKET_TAB && (
          <Tooltip tooltip={t("page.tickets.table.actions.focus.tooltip")}>
            <div>
              <Switch
                size="lg"
                label={t("page.tickets.table.actions.focus.label")}
                isChecked={isFocusModeOn}
                onChange={(e) => onUpdateFocus(e.currentTarget.checked)}
                icon={<Icon name={binocularsIcon} size={24} />}
              />
            </div>
          </Tooltip>
        )}
        {tab !== REMINDER_TICKET_TAB && (
          <div className="flex items-center gap-2">
            <Capture2>{t("page.tickets.table.actions.sort.label")}</Capture2>

            <Select
              items={sortingOptions}
              selected={sortingOptions.find((x) => x.value === sorting)}
              onChange={(x) => onSort(x.value)}
              renderOption={(x) => x.label}
              keySelector={(x) => x.value!}
              isRightAligned
              disabled={disableSorting}
            />
          </div>
        )}
      </div>
      <Button styling="secondary" onClick={onMarkAsRead} icon={<Icon name={iconCheck} size={16} />}>
        {t("page.tickets.table.actions.results.mark-all-read")}
      </Button>
    </div>
  );
}
