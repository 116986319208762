import type { ConnectedProjectDto, LanguageDto, ProjectDetailedDto } from "api/types";
import iconEdit05 from "assets/icons/edit-05.svg";
import { Button } from "components/Button/Button";
import { IconButton } from "components/Button/IconButton";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormField } from "components/Form/FormField";
import { FormInput } from "components/Form/FormInput";
import { FormInputAutocomplete } from "components/Form/FormInputAutocomplete";
import { FormSelect } from "components/Form/FormSelect";
import { Icon } from "components/Icon/Icon";
import { prepareSuggestions } from "components/InputAutocomplete/InputAutocomplete";
import { Modal } from "components/Modal/Modal";
import { DocumentPaper } from "components/Paper/DocumentPaper";
import { Headline4 } from "components/Text/Text";
import { createRequiredStringRule } from "helpers/rules";
import { useBool } from "hooks/useBool";
import { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

export interface LayoutProps {
  isSuperAdmin: boolean;
  languages: LanguageDto[];
  projects: ConnectedProjectDto[];
  isEditMode: boolean;
  defaultValues: Partial<FormValues>;
  isSubmitting: boolean;
  onSubmit: (data: FormValues) => void;
}

export function Layout({
  isSuperAdmin,
  isEditMode,
  defaultValues,
  languages,
  projects,
  isSubmitting,
  onSubmit,
}: LayoutProps): React.ReactNode {
  const { t } = useTranslation();
  const formMethods = useForm<FormValues>({ defaultValues });
  const language = useWatch<FormValues, "language">({ control: formMethods.control, name: "language" });
  const cloneProject = useWatch<FormValues, "cloneProject">({ control: formMethods.control, name: "cloneProject" });
  const owner = useWatch<FormValues, "owner">({ control: formMethods.control, name: "owner" });
  const [isSlugModalOpen, slugModalHandlers] = useBool();

  const allowedCloneProjects = useMemo(
    () => projects.filter((x) => x.projectBaseLanguageId === language.id),
    [projects, language.id],
  );

  const { setValue } = formMethods;
  useEffect(() => {
    if (cloneProject?.id && !allowedCloneProjects.some((x) => x.id === cloneProject.id)) {
      setValue("cloneProject", undefined);
    }
  }, [cloneProject?.id, allowedCloneProjects, setValue]);

  const citySuggestions = useMemo(() => prepareSuggestions(projects.map((x) => x.city)), [projects]);
  const ownerSuggestions = useMemo(() => prepareSuggestions(projects.map((x) => x.owner)), [projects]);
  const maintenanceSuggestions = useMemo(() => {
    const sameOwnerProjects = projects.filter((x) => x.owner === owner);

    return prepareSuggestions(sameOwnerProjects.map((x) => x.maintenance));
  }, [projects, owner]);

  const takenSlugs = useMemo(() => projects.map((x) => x.slug), [projects]);

  return (
    <DocumentPaper
      theme="constrained"
      title={isEditMode ? t("page.projects.edit.title") : t("page.projects.new.title")}
    >
      <div className="max-w-xs">
        <Form formMethods={formMethods} onSubmit={onSubmit}>
          <FormContent>
            <FormField label={t("page.projects.form.name")} required htmlFor="name">
              <FormInput<FormValues, "name">
                id="name"
                name="name"
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.projects.form.name"),
                  },
                }}
              />
            </FormField>
            {isEditMode && isSuperAdmin && (
              <FormField label={t("page.projects.form.slug")} required htmlFor="slug">
                <div className="flex items-center justify-center gap-2">
                  <div className="grow">
                    <FormInput id="slug" name="slug" readOnly />
                  </div>
                  <IconButton
                    styling="tertiary"
                    title={t("page.projects.form.slug.edit")}
                    onClick={slugModalHandlers.setTrue}
                  >
                    <Icon name={iconEdit05} size={16} />
                  </IconButton>
                </div>
              </FormField>
            )}
            <FormField label={t("page.projects.form.city")} required htmlFor="city">
              <FormInputAutocomplete
                id="city"
                name="city"
                suggestions={citySuggestions}
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.projects.form.city"),
                  },
                }}
              />
            </FormField>
            <FormField label={t("page.projects.form.owner")} required htmlFor="owner">
              <FormInputAutocomplete
                id="owner"
                name="owner"
                suggestions={ownerSuggestions}
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.projects.form.owner"),
                  },
                }}
              />
            </FormField>
            <FormField label={t("page.projects.form.maintenance")} required htmlFor="maintenance">
              <FormInputAutocomplete
                id="maintenance"
                name="maintenance"
                suggestions={maintenanceSuggestions}
                rules={{
                  validate: {
                    required: createRequiredStringRule(t, "page.projects.form.maintenance"),
                  },
                }}
              />
            </FormField>
            <FormField label={t("page.projects.form.type")}>
              <FormSelect<FormValues, FormValues["type"]>
                name="type"
                items={["addressBased", "companyBased"]}
                keySelector={(x) => x}
                renderOption={(x) => (x === "addressBased" ? "address" : "company")}
              />
            </FormField>
            <FormField label={t("page.projects.form.language")} htmlFor="language">
              <FormSelect<FormValues, LanguageDto>
                id="language"
                name="language"
                keySelector={(x) => x.id}
                items={languages}
                renderOption={(x) => x.description}
                disabled={isEditMode}
              />
            </FormField>

            {isEditMode ? null : (
              <FormField label={t("page.projects.form.clone")} htmlFor="clone">
                <FormSelect<FormValues, ConnectedProjectDto>
                  id="clone"
                  name="cloneProject"
                  emptyItem={t("page.projects.form.clone.placeholder")}
                  items={allowedCloneProjects}
                  keySelector={(x) => x.id}
                  renderOption={(x) => x.name}
                />
              </FormField>
            )}

            <Button type="submit" isLoading={isSubmitting}>
              {isEditMode ? t("page.projects.edit.submit") : t("page.projects.new.submit")}
            </Button>
          </FormContent>
        </Form>
        <Modal isOpen={isSlugModalOpen} onRequestClose={slugModalHandlers.setFalse}>
          <SlugModal
            initialSlug={defaultValues.slug || ""}
            takenSlugs={takenSlugs}
            onSubmit={(slug) => {
              setValue("slug", slug);
              slugModalHandlers.setFalse();
            }}
            onClose={() => {
              slugModalHandlers.setFalse();
            }}
          />
        </Modal>
      </div>
    </DocumentPaper>
  );
}

interface FormValues {
  name: string;
  slug?: string;
  city: string;
  owner: string;
  maintenance: string;
  language: LanguageDto;
  type: ProjectDetailedDto["type"];
  isZiezodan: boolean;
  cloneProject?: ConnectedProjectDto;
}

function SlugModal({
  initialSlug,
  takenSlugs,
  onSubmit,
  onClose,
}: {
  initialSlug: string;
  takenSlugs: string[];
  onSubmit: (slug: string) => void;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const form = useForm<SlugModalValues>({
    defaultValues: {
      slug: initialSlug,
    },
  });

  return (
    <Form<SlugModalValues> formMethods={form} onSubmit={(data) => onSubmit(data.slug.trim().toLowerCase())} noPrompt>
      <div className="flex max-w-lg flex-col items-center gap-4 p-4 text-left">
        <div className="flex flex-col gap-2 text-left">
          <Headline4>{t("page.projects.slug-modal.title")}</Headline4>
          <div className="rounded bg-yellow-lighter p-4">
            <div className="flex gap-2">
              <span>⚠️</span>
              <p>{t("page.projects.slug-modal.warning")}</p>
            </div>
          </div>
        </div>

        <div className="w-full">
          <FormContent maxWidth="4xl">
            <FormInput<SlugModalValues>
              id="slug"
              name="slug"
              rules={{
                maxLength: {
                  message: t("components.form.error.max-length", {
                    length: 64,
                  }),
                  value: 64,
                },
                validate: {
                  required: createRequiredStringRule(t, "page.projects.form.slug"),
                  mustNotContainSpecialCharacters: (value) => {
                    if (!value) {
                      return undefined;
                    }

                    return /[^A-Za-z0-9-]/.test(value) ? t("components.form.error.no-special-characters") : undefined;
                  },
                  mustBeUnique: (value) => {
                    if (!value) {
                      return undefined;
                    }

                    if (value === initialSlug) {
                      return undefined;
                    }

                    return takenSlugs.includes(value) ? t("page.projects.slug-modal.error.not-unique") : undefined;
                  },
                },
              }}
            />
          </FormContent>
        </div>

        <div className="flex items-center justify-center gap-2">
          <Button type="button" styling="secondary" onClick={onClose}>
            {t("page.projects.slug-modal.cancel")}
          </Button>
          <Button type="submit">{t("page.projects.slug-modal.submit")}</Button>
        </div>
      </div>
    </Form>
  );
}

interface SlugModalValues {
  slug: string;
}
