import type { InfiniteData } from "@tanstack/react-query";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "api/hooks/useApi";
import type {
  CommentCreateRequest,
  CommentDto,
  CommentDtoPaginationResultDto,
  CommentUpdateRequest,
  MessageStatusChangeRequest,
  MessageUpdateRequest,
  MessageV2Dto,
} from "api/types";
import arrowDownIcon from "assets/icons/arrow-narrow-down.svg";
import iconCheck from "assets/icons/check.svg";
import iconCopy01 from "assets/icons/copy-01.svg";
import iconEdit05 from "assets/icons/edit-05.svg";
import iconEyeOff from "assets/icons/eye-off.svg";
import iconFlag01 from "assets/icons/flag-01.svg";
import iconLock01 from "assets/icons/lock-01.svg";
import iconMarkerPin01 from "assets/icons/marker-pin-01.svg";
import iconShare01 from "assets/icons/share-01.svg";
import iconTrash02 from "assets/icons/trash-02.svg";
import iconZap from "assets/icons/zap.svg";
import { Button } from "components/Button/Button";
import { ConfirmModal } from "components/ConfirmModal/ConfirmModal";
import type { FormDocument } from "components/DocumentInput/useDocumentFile";
import { useFlashToast } from "components/FlashToast/FlashToast";
import { Form } from "components/Form/Form";
import { FormContent } from "components/Form/FormContent";
import { FormDateAndTimePicker } from "components/Form/FormDateAndTimePicker";
import { FormDocumentInput } from "components/Form/FormDocumentInput";
import { FormImageInput } from "components/Form/FormImageInput";
import { FormInput } from "components/Form/FormInput";
import { FormTextArea } from "components/Form/FormTextArea";
import { formatDate, FormattedDate } from "components/FormattedDate/FormattedDate";
import { formatDistance } from "components/FormattedDistance/FormattedDistance";
import { FullSizeLoader } from "components/FullSizeLoader/FullSizeLoader";
import { Gallery } from "components/Gallery/Gallery";
import { Icon } from "components/Icon/Icon";
import type { FormImage } from "components/ImageInput/useImageInput";
import { Label } from "components/Label/Label";
import { LinkFormatter } from "components/LinkFormatter/LinkFormatter";
import { Modal } from "components/Modal/Modal";
import { Pdf } from "components/Pdf/Pdf";
import { PillButton } from "components/PillButton/PillButton";
import { Capture1, Subtitle2 } from "components/Text/Text";
import { addMinutes } from "date-fns";
import { getFromConfig } from "helpers/config";
import { validateSize } from "helpers/file-size";
import { commonAPIDataSelector } from "helpers/Network/selectors";
import { createRequiredStringRule } from "helpers/rules";
import {
  isDefined,
  isLongerThanCharLimit,
  isLongerThanWordLimit,
  limitTextByChar,
  limitTextByWord,
} from "helpers/util";
import { useProjectId } from "hooks/Network/useProjectId";
import { useSessionUser } from "hooks/Network/useSessionUser";
import { useUploadDocument } from "hooks/Network/useUploadDocument";
import { useUploadImage } from "hooks/Network/useUploadImage";
import { useBool } from "hooks/useBool";
import { usePermission } from "hooks/usePermission";
import { useSignalRHub, useSignalRSubscription } from "hooks/useSignalR";
import { useSlug } from "hooks/useSlug";
import { QUERY_KEYS } from "query-keys";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { twJoin } from "tailwind-merge";
import type { ApiResponseType } from "types/api-types";

import { ArchivePostModal } from "./ArchivePost";
import { CommentButton, LikeButton, LikeCountButton } from "./Buttons";
import { CommentLikes } from "./CommentLikes";
import { CommentList } from "./CommentList";
import { CommunityItem } from "./CommunityItem";
import { InformAdmin } from "./InformAdmin";
import { InformedAdmins } from "./InformedAdmins";
import { LikeList } from "./LikeList";
import { PromotedGroupCard } from "./PromotedGroupCard";
import { ReportPost } from "./ReportPost";

interface ExternalProps {
  message: MessageV2Dto;
  onJoinGroup?: ({ groupId }: { groupId: string }) => Promise<void>;
  isExpanded: boolean;
}

interface FormValues {
  title: string;
  body: string;
  images: FormImage[];
  documents: FormDocument[];
  scheduled?: Date;
}

enum Tabs {
  LIKES,
  COMMENTS,
}

enum READ_MORE_BTN {
  NONE,
  EXPAND,
  OPEN_DETAILS,
}

const LIKES_AND_COMMENTS_LENGTH = 5;
const CHAR_LIMIT = 180;
const WORD_LIMIT = 500;

export function CommunityPost({ message, isExpanded, onJoinGroup }: ExternalProps): React.ReactNode {
  const response = useData(message, isExpanded);

  if (!response) {
    return null;
  }

  return <CommunityPostWithData {...response} isExpanded={isExpanded} onJoinGroup={onJoinGroup} />;
}

export function CommunityPostWithData({
  post,
  likes,
  fetchLikes,
  onLike,
  onUnlike,
  onLikeComment,
  onUnlikeComment,
  comments,
  fetchComments,
  isMarkingAsRead,
  commentPost,
  markAsRead,
  deletePost,
  archivePost,
  copyQuickReply,
  editPost,
  editComment,
  deleteComment,
  isExpanded,
  activeTab,
  setActiveTab,
  isSubmitting,
  analyze,
  onAnalyze,
  analysis,
  onCloseAnalyze,
  onTranslate,
  onHideTranslation,
  translation,
  translationIsLoading,
  onGroupFollowChange,
  onJoinGroup,
  showNewCommentPill,
  onFetchNewComments,
  sessionUser,
}: ReturnType<typeof useData> & {
  isExpanded: boolean;
  onJoinGroup?: ({ groupId }: { groupId: string }) => Promise<void>;
}): React.ReactNode {
  const slug = useSlug();
  const { t, i18n } = useTranslation();
  const hasPermission = usePermission();
  const [isEditing, editPostHandler] = useBool(false);
  const [isCancelEditModalOpen, cancelEditHandler] = useBool(false);
  const [isDeleteModalOpen, deleteHandler] = useBool(false);
  const [isArchiveModalOpen, archiveHandler] = useBool(false);
  const [isInformModalOpen, informModalHandler] = useBool(false);
  const [isInformedModalOpen, informedModalHandler] = useBool(false);
  const [isReportModalOpen, reportModalHandler] = useBool(false);
  const [viewLikesState, setViewLikesState] = useState<{ open: boolean; commentId?: string; likeCount?: number }>({
    open: false,
  });
  const [readMoreButton, setReadMoreButton] = useState(() =>
    post?.content
      ? isExpanded || !isLongerThanCharLimit(post.content, CHAR_LIMIT)
        ? READ_MORE_BTN.NONE
        : READ_MORE_BTN.EXPAND
      : READ_MORE_BTN.NONE,
  );
  const minScheduled = useMemo(() => addMinutes(new Date(), 5), []);

  const form = useForm<FormValues>({
    defaultValues: {
      title: post?.title,
      body: post?.content,
      images: post?.images,
      documents: post?.documents,
      scheduled: post?.scheduledAt ? new Date(post.scheduledAt) : undefined,
    },
  });

  const images = useWatch<FormValues, "images">({ control: form.control, name: "images" });
  const documents = useWatch<FormValues, "documents">({ control: form.control, name: "documents" });

  async function handleEdit() {
    const formValues = form.getValues();

    const payload: MessageUpdateRequest = {
      title: formValues.title,
      content: formValues.body,
      scheduledAt: formValues.scheduled ? formValues.scheduled.toISOString() : undefined,
    };

    await editPost({
      payload: payload,
      images: formValues.images,
      documents: formValues.documents,
      successMessage: t("page.message-feed.edit.notifications.success"),
      failureMessage: t("page.message-feed.edit.notifications.error"),
    });
    editPostHandler.setFalse();
  }

  const handleTabSelection = async (selectedTab: Tabs) => {
    switch (selectedTab) {
      case activeTab:
        setActiveTab(undefined);
        break;
      case Tabs.LIKES:
        if (!fetchLikes.isSuccess) await fetchLikes.fetchNextPage();
        setActiveTab(Tabs.LIKES);
        break;
      case Tabs.COMMENTS:
        setActiveTab(Tabs.COMMENTS);
        if (!fetchComments.isSuccess) await fetchComments.fetchNextPage();
        break;
      default:
        break;
    }
  };

  const handleLike = async () => {
    if (post?.isLiked) {
      await onUnlike();
    } else {
      await onLike();
    }
  };

  const postContent = useMemo(() => {
    if (!post?.content) {
      return;
    }

    if (readMoreButton === READ_MORE_BTN.EXPAND) {
      return limitTextByChar(post.content, CHAR_LIMIT);
    }

    if (readMoreButton === READ_MORE_BTN.OPEN_DETAILS) {
      return limitTextByWord(post.content, WORD_LIMIT);
    }

    return post.content;
  }, [post?.content, readMoreButton]);

  const handleReadMore = useCallback(() => {
    if (!post?.content) return;

    if (isLongerThanWordLimit(post.content, WORD_LIMIT)) {
      setReadMoreButton(READ_MORE_BTN.OPEN_DETAILS);
    } else {
      setReadMoreButton(READ_MORE_BTN.NONE);
    }
  }, [post]);

  const handleGroupFollowChange = useCallback(
    (id: string, action: "join" | "leave") => {
      void onGroupFollowChange();
      if (action === "join") {
        void onJoinGroup?.({ groupId: id });
      }
    },
    [onGroupFollowChange, onJoinGroup],
  );

  useEffect(() => {
    if (activeTab === Tabs.LIKES && !likes.length) {
      setActiveTab(undefined);
    }
  }, [activeTab, likes.length, setActiveTab]);

  const onClickTranslate = () => {
    if (translation) onHideTranslation();
    else onTranslate();
  };

  const actions = useMemo(() => {
    const actions = [];

    if (!post || post?.deletedAt) return [];

    if (hasPermission((x) => x.isSuperAdmin)) {
      actions.push({
        dataTestId: "post-context-menu-inform-admin",
        text: "Analyze 🤖",
        icon: <Icon name={iconZap} size={16} />,
        callback: onAnalyze,
      });

      actions.push({
        dataTestId: "post-context-menu-copy",
        text: t("component.community-post.actions.quick-reply-link"),
        icon: <Icon name={iconCopy01} size={16} />,
        callback: copyQuickReply,
      });
    }

    if (post.isUnread) {
      actions.push({
        dataTestId: "post-context-menu-mark-read",
        text: t("component.community-post.actions.mark-as-read"),
        icon: <Icon name={iconCheck} size={16} />,
        callback: markAsRead,
      });
    }

    if (post.canEdit) {
      actions.push({
        dataTestId: "context-menu-edit-btn",
        text: t("component.community-post.actions.edit"),
        icon: <Icon name={iconEdit05} size={16} />,
        callback: editPostHandler.toggle,
      });
    }

    if (post.canShare) {
      actions.push({
        dataTestId: "post-context-menu-inform",
        text: t("component.community-post.actions.inform"),
        icon: <Icon name={iconShare01} size={16} />,
        callback: informModalHandler.toggle,
      });
    }

    if (post.canDelete) {
      if (sessionUser.isAdmin || !post.canArchive) {
        actions.push({
          dataTestId: "context-menu-delete-btn",
          text: t("component.community-post.actions.delete"),
          icon: <Icon name={iconTrash02} size={16} />,
          callback: deleteHandler.toggle,
        });
      } else {
        actions.push({
          dataTestId: "post-context-menu-archive",
          text: t("component.community-post.actions.close"),
          icon: <Icon name={iconCheck} size={16} />,
          callback: archiveHandler.toggle,
        });
      }
    }

    if (post.canReport) {
      actions.push({
        dataTestId: "post-context-menu-report",
        text: t("component.community-post.actions.report"),
        icon: <Icon name={iconFlag01} size={16} />,
        callback: reportModalHandler.toggle,
      });
    }

    return actions;
  }, [
    post,
    sessionUser,
    hasPermission,
    onAnalyze,
    t,
    copyQuickReply,
    markAsRead,
    editPostHandler.toggle,
    informModalHandler.toggle,
    deleteHandler.toggle,
    archiveHandler.toggle,
    reportModalHandler.toggle,
  ]);

  if (!post) {
    return null;
  }

  const content = translation?.content || postContent;

  return (
    <>
      <CommunityItem
        ring={
          !!post.deletedAt || !!post.archivedAt
            ? "greyed-out"
            : post.isUnread
              ? "unread"
              : post.reportedAt
                ? "reported"
                : undefined
        }
        user={post.author}
        actions={actions}
        group={post.group}
        labels={[
          post.projectConnection ? (
            <Label theme="aopDarkBlue">
              <Icon name={iconMarkerPin01} size={16} />
              {post.projectConnection.name}
            </Label>
          ) : undefined,
          post.reportedAt && !post.deletedAt ? (
            <Label data-testid="flagged-label" theme="red">
              {t("component.community-post.flagged")}
            </Label>
          ) : undefined,
          post.hasAdminBeenInformed && !post.deletedAt ? (
            <Label data-testid="post-informed-label" theme="green" onClick={() => informedModalHandler.toggle()}>
              {t("component.community-post.shared")}
            </Label>
          ) : undefined,
          post.type === "announcement" || post.type === "announcementReadOnly" ? (
            <Label theme="blue">{t("component.community-post.announcement")}</Label>
          ) : undefined,
          post.deletedAt ? <Label theme="red">{t("component.community-post.removed")}</Label> : undefined,
        ].filter(isDefined)}
        dateSubtitle={
          post.scheduledAt ? (
            <span className="text-red-dark" data-testid="post-scheduled-date">
              {t("component.community-post.scheduled-for", {
                scheduled: formatDate(i18n, "datetime", post.scheduledAt),
              })}
            </span>
          ) : post.postedAt ? (
            post.archivedAt ? (
              <p>
                <FormattedDate format="datetime" date={post.postedAt} /> •{" "}
                <span className="text-sm font-semibold uppercase">{t("component.community-post.archived")}</span>
              </p>
            ) : post.updatedAt ? (
              <p>
                <FormattedDate format="datetime" date={post.postedAt} /> •{" "}
                <span className="text-sm font-semibold uppercase">{t("component.community-post.edited")}</span>
              </p>
            ) : (
              <FormattedDate format="datetime" date={post.postedAt} />
            )
          ) : undefined
        }
        onClick={!isMarkingAsRead && post.isUnread ? markAsRead : undefined}
        warningHeaders={[
          post.isHiddenFromAdmins
            ? {
                icon: <Icon name={iconEyeOff} size={16} />,
                text: t("component.community-post.hidden-from-property-manager"),
              }
            : undefined,
          !post.canComment && sessionUser.role.type !== "readOnly"
            ? { icon: <Icon name={iconLock01} size={16} />, text: t("component.community-post.no-comment-allowed") }
            : undefined,
        ].filter(isDefined)}
      >
        <div className="flex flex-col gap-2 md:flex-row">
          <div className="flex flex-1 flex-col gap-2">
            {!!post.deletedAt && !sessionUser.isSuperAdmin ? (
              <p className="whitespace-pre-line text-grey">{t("component.community-post.content.removed")}</p>
            ) : isEditing ? (
              <Form formMethods={form} onSubmit={handleEdit}>
                <FormContent>
                  <FormInput<FormValues>
                    name="title"
                    placeholder={t("page.message-feed.create.title.placeholder")}
                    rules={{
                      validate: {
                        required: createRequiredStringRule(t, "page.message-feed.create.title"),
                      },
                    }}
                  />
                  <FormTextArea<FormValues>
                    className="max-h-[300px]"
                    name="body"
                    placeholder={t("page.message-feed.create.body.placeholder")}
                    rules={{
                      validate: {
                        required: createRequiredStringRule(t, "page.message-feed.create.body"),
                      },
                    }}
                  />
                  {post.relatedGroup ? (
                    <PromotedGroupCard
                      id={post.relatedGroup.id}
                      name={post.relatedGroup.name}
                      description={post.relatedGroup.description}
                      image={post.relatedGroup.image}
                      isReadonly
                      isResidentGroup={post.relatedGroup.isResidentGroup}
                    />
                  ) : (
                    <div className="flex gap-2">
                      {!(documents?.length > 0) && (
                        <FormImageInput<FormValues, "images">
                          name="images"
                          rules={{
                            validate: {
                              size(images) {
                                if (images) {
                                  return validateSize(t, images);
                                }
                              },
                            },
                          }}
                        />
                      )}
                      {!(images?.length > 0) && (
                        <FormDocumentInput<FormValues, "documents">
                          name="documents"
                          accept="application/pdf"
                          withPreview
                          rules={{
                            validate: {
                              size(documents) {
                                if (documents) {
                                  return validateSize(t, documents);
                                }
                              },
                            },
                          }}
                        />
                      )}
                    </div>
                  )}
                  {post.scheduledAt ? (
                    <FormDateAndTimePicker<FormValues>
                      name="scheduled"
                      type="datetime"
                      min={minScheduled}
                      rules={{
                        validate: {
                          laterThanMin: (date) => {
                            if (date == null) {
                              return undefined;
                            }

                            return date < minScheduled
                              ? t("page.message-feed.create.schedule.datetime.error.must-be-in-future")
                              : undefined;
                          },
                        },
                        required: t("components.form.error.required", {
                          inputName: t("page.message-feed.create.schedule.datetime"),
                        }),
                      }}
                    />
                  ) : null}
                </FormContent>
                <div className="mt-4 flex justify-end gap-4">
                  <Button styling="secondary" onClick={cancelEditHandler.setTrue}>
                    {t("common.action.cancel")}
                  </Button>
                  <Button styling="primary" type="submit" isLoading={isSubmitting}>
                    {t("common.action.save")}
                  </Button>
                </div>
              </Form>
            ) : (
              <>
                <p
                  className={twJoin("whitespace-pre-line font-semibold", post.deletedAt && "text-grey line-through")}
                  data-testid="post-title"
                >
                  {translation?.title || post.title}
                </p>
                {content && (
                  <p
                    className={post.deletedAt ? "text-grey line-through" : "text-grey-darkest"}
                    data-testid="post-content"
                  >
                    <LinkFormatter>{content}</LinkFormatter>
                  </p>
                )}
                <div className="flex flex-col gap-2">
                  {translation ? null : readMoreButton === READ_MORE_BTN.EXPAND ? (
                    <Button styling="ghostPrimary" onClick={handleReadMore}>
                      {t("component.community-post.content.read-more")}
                    </Button>
                  ) : (
                    readMoreButton === READ_MORE_BTN.OPEN_DETAILS && (
                      <Button
                        type="link"
                        styling="ghostPrimary"
                        href={routes.messageFeed.details({ slug, id: post.id })}
                      >
                        {t("component.community-post.content.read-more")}
                      </Button>
                    )
                  )}
                  {!isEditing && post.languageIsoCode !== sessionUser.language.id && (
                    <Button
                      styling="ghostPrimary"
                      data-testid="translate-btn"
                      isLoading={translationIsLoading}
                      onClick={onClickTranslate}
                    >
                      {translation
                        ? t("component.community-post.content.translate.original")
                        : t("component.community-post.content.translate")}
                    </Button>
                  )}
                </div>
                {post.relatedGroup ? (
                  <PromotedGroupCard
                    id={post.relatedGroup.id}
                    name={post.relatedGroup.name}
                    description={post.relatedGroup.description}
                    image={post.relatedGroup.image}
                    isResidentGroup={post.relatedGroup.isResidentGroup}
                    isOwner={post.relatedGroup.isOwner}
                    isMember={post.relatedGroup.isMember}
                    followCallback={handleGroupFollowChange}
                  />
                ) : null}
                <Gallery images={post.images} size="small" />
                {post.documents?.length ? (
                  <div className="flex flex-wrap gap-2">
                    {post.documents.map((x) => (
                      <Pdf
                        key={x.id}
                        previewImage={x.previewImage}
                        fileName={x.fileName}
                        fileSize={x.fileSize}
                        onClick={() => window.open(x.url, "_blank")}
                      />
                    ))}
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
        {!post.scheduledAt && (
          <div
            className={twJoin(
              "-my-1 flex flex-col items-center justify-between gap-4 @xl:flex-row",
              activeTab !== undefined && "border-b border-b-grey-lighter pb-2",
            )}
          >
            <div className="flex items-center gap-2">
              {!post.archivedAt && !post.deletedAt && <LikeButton isActive={post.isLiked} onClick={handleLike} />}
              <LikeCountButton
                total={post.totalLikeCount}
                onClick={() => handleTabSelection(Tabs.LIKES)}
                isActive={activeTab === Tabs.LIKES}
                isGreyedOut={!!post.archivedAt || !!post.deletedAt}
              />
              <CommentButton
                isActive={activeTab === Tabs.COMMENTS}
                isGreyedOut={!!post.archivedAt || !!post.deletedAt}
                total={post.totalCommentCount}
                onClick={() => handleTabSelection(Tabs.COMMENTS)}
              />
            </div>
            {post.lastActivityAt && (
              <span className="order-first text-sm text-grey @xl:order-last">
                {t("component.community-post.last-activity", {
                  time: formatDistance(i18n, { start: new Date(post.lastActivityAt) }),
                })}
              </span>
            )}
          </div>
        )}
        {activeTab === Tabs.LIKES && likes && (
          <LikeList likes={likes} hasMoreLikes={!!fetchLikes.hasNextPage} onLoadMore={fetchLikes.fetchNextPage} />
        )}
        {activeTab === Tabs.COMMENTS && comments && (
          <div className="relative">
            <CommentList
              messageId={post.id}
              comments={comments}
              isLoadingComments={fetchComments.isFetching}
              hasMoreComments={!!fetchComments.hasNextPage}
              onLoadMore={fetchComments.fetchNextPage}
              commentPost={commentPost}
              editComment={editComment}
              deleteComment={deleteComment}
              onLikeComment={onLikeComment}
              onUnlikeComment={onUnlikeComment}
              onViewCommentLikes={(x) =>
                setViewLikesState({ open: true, commentId: x.commentId, likeCount: x.likeCount })
              }
              isGreyedOut={!post.canComment}
            />
            {showNewCommentPill ? (
              <PillButton className="absolute bottom-12 left-1/2" onClick={onFetchNewComments}>
                <Icon name={arrowDownIcon} size={16} />
                <Capture1>{t("component.community-post.comments.load-new")}</Capture1>
              </PillButton>
            ) : null}
          </div>
        )}
      </CommunityItem>
      <ConfirmModal
        id="cancel-edit-modal"
        title={t("component.community-post.cancel-edit.modal.title")}
        description={t("component.community-post.cancel-edit.modal.description")}
        isLoading={false}
        onReject={cancelEditHandler.setFalse}
        rejectBtnProps={{
          "data-testid": "cancel-edit-modal-cancel",
        }}
        onResolve={() => {
          form.reset();
          editPostHandler.setFalse();
          cancelEditHandler.setFalse();
        }}
        resolveBtnProps={{
          text: t("common.action.delete"),
          "data-testid": "cancel-edit-modal-confirm",
        }}
        isOpen={isCancelEditModalOpen}
        shouldCloseOnEsc
        data-testid="cancel-edit-modal"
      />
      <ConfirmModal
        id="delete-modal"
        title={t("component.community-post.delete.modal.title")}
        description={t("component.community-post.delete.modal.description")}
        isLoading={false}
        theme="danger"
        onReject={deleteHandler.setFalse}
        rejectBtnProps={{
          "data-testid": "delete-modal-cancel",
        }}
        onResolve={() => {
          void deletePost({ reason: "undefined" });
          deleteHandler.setFalse();
        }}
        resolveBtnProps={{
          "data-testid": "modal-confirm-delete",
          text: t("common.action.delete"),
        }}
        isOpen={isDeleteModalOpen}
        shouldCloseOnEsc
        data-testid="cancel-edit-modal"
      />
      <Modal
        isOpen={isInformModalOpen}
        onRequestClose={() => {
          informModalHandler.setFalse();
        }}
        overflowYVisible
      >
        <InformAdmin messageId={post.id} onClose={informModalHandler.setFalse} />
      </Modal>
      <Modal isOpen={isInformedModalOpen} onRequestClose={() => informedModalHandler.setFalse()} shouldCloseOnEsc>
        <InformedAdmins messageId={post.id} onClose={informedModalHandler.setFalse} />
      </Modal>
      <Modal
        isOpen={isReportModalOpen}
        onRequestClose={() => reportModalHandler.setFalse()}
        shouldCloseOnEsc
        overflowYVisible
      >
        <ReportPost messageId={post.id} onClose={reportModalHandler.setFalse} />
      </Modal>
      <Modal
        isOpen={viewLikesState.open}
        onRequestClose={() => setViewLikesState((x) => ({ ...x, open: false }))}
        onAfterClose={() => setViewLikesState({ open: false })}
        shouldCloseOnEsc
      >
        {viewLikesState.commentId ? (
          <CommentLikes
            messageId={post.id}
            commentId={viewLikesState.commentId}
            initialLikes={viewLikesState.likeCount}
          />
        ) : null}
      </Modal>
      <ConfirmModal
        isOpen={analyze}
        title="Analysis"
        description="An analysis of how good your post is and what topics it covers."
        isLoading={!analysis}
        id="analysis"
        onResolve={() => onCloseAnalyze()}
        onReject={() => onCloseAnalyze()}
      >
        {analysis?.topics && analysis?.sentiment ? (
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2">
              <Subtitle2>Topics:</Subtitle2>
              <span>{analysis.topics?.topics?.length > 1 ? analysis.topics?.topics.join(", ") : "-"}</span>
            </div>
            <div className="flex items-center gap-2">
              <Subtitle2>Sentiment:</Subtitle2>
              <span className="text-lg">
                {analysis.sentiment?.score || "-"}
                <span className="text-base text-grey-dark">/{analysis.sentiment?.outOf || "10"}</span>
              </span>
            </div>
          </div>
        ) : (
          <div className="px-10 py-5">
            <FullSizeLoader />
          </div>
        )}
      </ConfirmModal>
      <ArchivePostModal
        isOpen={isArchiveModalOpen}
        onClose={archiveHandler.setFalse}
        onDelete={deletePost}
        onArchive={archivePost}
      />
    </>
  );
}

function useData(message: MessageV2Dto, isExpanded: boolean) {
  const projectId = useProjectId();
  const sessionUser = useSessionUser();
  const api = useApi();
  const queryClient = useQueryClient();
  const showFlashToast = useFlashToast();
  const { uploadFormImage, isUploadingImage } = useUploadImage();
  const { uploadFormDocument, isUploadingDocument } = useUploadDocument();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<Tabs | undefined>(isExpanded ? Tabs.COMMENTS : undefined);
  const [analyze, analyzeHandler] = useBool();
  const [showNewCommentPill, newCommentPillHandler] = useBool(false);

  const { signalRConnection } = useSignalRHub("community-feed-detail-hub", {
    query: `userId=${sessionUser.id}&entityId=${message.id}&entityType=Message`,
    disabled: activeTab !== Tabs.COMMENTS,
  });

  useEffect(() => {
    queryClient.setQueryData(QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id), message);
  }, [queryClient, message, projectId]);

  const { data: sentiment } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_AI_SENTIMENT(projectId, message.id),
    queryFn: () => api.postMessagesAiSentimentV1(message.id),
    enabled: analyze,
    select: commonAPIDataSelector,
  });
  const { data: topics } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_AI_TOPICS(projectId, message.id),
    queryFn: () => api.postMessagesAiTopicsV1(message.id),
    enabled: analyze,
    select: commonAPIDataSelector,
  });

  const { data: messageDetails, error: messageError } = useQuery({
    queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id),
    queryFn: () => api.getMessagesDetailsV2(message.id).then((x) => x.data),
    initialData: message,
    staleTime: Infinity,
  });

  const likes = useInfiniteQuery({
    queryKey: QUERY_KEYS.MESSAGES_LIKES(projectId, message.id),
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await api.getMessagesLikesV1(message.id, {
        Offset: pageParam * LIKES_AND_COMMENTS_LENGTH,
        Limit: LIKES_AND_COMMENTS_LENGTH,
      });

      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    enabled: false,
  });

  const comments = useInfiniteQuery({
    queryKey: QUERY_KEYS.MESSAGES_COMMENTS(projectId, message.id),
    queryFn: async ({ pageParam = 0 }) => {
      const { data } = await api.getMessagesCommentsV1(message.id, {
        Offset: pageParam * LIKES_AND_COMMENTS_LENGTH,
        Limit: LIKES_AND_COMMENTS_LENGTH,
      });

      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage.hasMore) {
        return undefined;
      }

      return pages.length;
    },
    enabled: false,
  });

  const createQuickReplyLink = useMutation({
    mutationFn: () =>
      api
        .postQuickReplyMessageCreateTokenV1({ messageId: message.id as any, userId: sessionUser.id as any })
        .then((x) => x.data),
    onSuccess: async (token) => {
      const dashboardUrl = await getFromConfig("newDashboardRootUrl");
      const quickReplyMessagePath = routes.quickReplyMessage({ token });
      await navigator.clipboard.writeText(`${dashboardUrl}${quickReplyMessagePath}`);
      showFlashToast({ type: "success", title: t("component.community-post.quick-reply-link.success") });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.quick-reply-link.error") });
    },
  });

  const deletePost = useMutation({
    mutationFn: ({ reason, details }: Omit<MessageStatusChangeRequest, "newStatus">) =>
      api.postMessagesChangeStatusV1(message.id, { newStatus: "deleted", reason: reason, details: details }),
    onSuccess: async () => {
      showFlashToast({ type: "success", title: t("component.community-post.delete.success") });

      if (sessionUser.isSuperAdmin) {
        await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      } else {
        await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES(projectId) });
      }
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.delete.error") });
    },
  });

  const archivePost = useMutation({
    mutationFn: ({ reason, details }: Omit<MessageStatusChangeRequest, "newStatus">) =>
      api.postMessagesChangeStatusV1(message.id, { newStatus: "archived", reason: reason, details: details }),
    onSuccess: async () => {
      showFlashToast({ type: "success", title: t("component.community-post.archive.success") });

      if (sessionUser.isSuperAdmin) {
        await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      } else {
        await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES(projectId) });
      }
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.archive.error") });
    },
  });

  const editPost = async ({
    payload,
    images,
    documents,
    successMessage,
    failureMessage,
  }: {
    payload: MessageUpdateRequest;
    images: FormImage[];
    documents: FormDocument[];
    successMessage: string;
    failureMessage: string;
  }) => {
    const uploadedImage = await uploadFormImage(images[0]);
    const uploadedDocument = await uploadFormDocument(documents[0]);

    return await updateMessage.mutateAsync({
      payload: { ...payload, imageId: uploadedImage?.id, documentIds: uploadedDocument ? [uploadedDocument?.id] : [] },
      successMessage: successMessage,
      failureMessage: failureMessage,
    });
  };

  const updateMessage = useMutation({
    mutationFn: ({ payload }: { payload: MessageUpdateRequest; successMessage: string; failureMessage: string }) =>
      api.putMessagesV1(message.id, payload),
    onSuccess: async (_, { successMessage }) => {
      showFlashToast({ type: "success", title: successMessage });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
    },
    onError(_, { failureMessage }) {
      showFlashToast({ type: "error", title: failureMessage });
    },
  });

  const likePost = useMutation({
    mutationFn: () => api.postMessagesLikeV1(message.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      await likes.refetch();
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.like.error") });
    },
  });

  const unlikePost = useMutation({
    mutationFn: () => api.deleteMessagesLikeV1(message.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      await likes.refetch();
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.unlike.error") });
    },
  });

  const likeComment = useMutation({
    mutationFn: (commentId: string) => api.postMessagesCommentsLikesV1(message.id, commentId),
    onSuccess: async (_, commentId: string) => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_COMMENTS(projectId, message.id) });
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.MESSAGE_COMMENT_DETAILS(projectId, message.id, commentId),
      });
      await comments.refetch();
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.comment.like.error") });
    },
  });

  const unlikeComment = useMutation({
    mutationFn: (commentId: string) => api.deleteMessagesCommentsLikesV1(message.id, commentId),
    onSuccess: async (_, commentId: string) => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_COMMENTS(projectId, message.id) });
      await queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.MESSAGE_COMMENT_DETAILS(projectId, message.id, commentId),
      });
      await comments.refetch();
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.comment.unlike.error") });
    },
  });

  const createComment = async ({
    comment,
    files,
    failureMessage,
    parentId,
  }: {
    comment: string;
    files: FormImage[];
    failureMessage: string;
    parentId?: string;
  }) => {
    const uploadedImage = await uploadFormImage(files[0]);

    return await commentPost.mutateAsync({
      payload: { content: comment, imageId: uploadedImage?.id, parentId },
      failureMessage: failureMessage,
    });
  };

  const commentPost = useMutation({
    mutationFn: ({ payload }: { payload: CommentCreateRequest; failureMessage: string }) =>
      api.postMessagesCommentsV1(message.id, payload).then((x) => x.data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGE_COMMENTS_DETAILS(projectId, message.id) });
      await onFetchNewComments();
    },
    onError(_, { failureMessage }) {
      showFlashToast({ type: "error", title: failureMessage });
    },
  });

  const editComment = async ({
    comment,
    commentId,
    files,
    failureMessage,
  }: {
    comment: string;
    commentId: string;
    files: FormImage[];
    failureMessage: string;
  }) => {
    const uploadedImage = await uploadFormImage(files[0]);

    return await updateComment.mutateAsync({
      payload: { content: comment, imageId: uploadedImage?.id },
      commentId: commentId,
      failureMessage: failureMessage,
    });
  };

  const updateComment = useMutation({
    mutationFn: ({
      payload,
      commentId,
    }: {
      payload: CommentUpdateRequest;
      commentId: string;
      failureMessage: string;
    }) => api.putMessagesCommentsV1(message.id, commentId, payload).then((x) => x.data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGE_COMMENTS_DETAILS(projectId, message.id) });

      await onFetchNewComments();
    },
    onError(_, { failureMessage }) {
      showFlashToast({ type: "error", title: failureMessage });
    },
  });

  const deleteComment = useMutation({
    mutationFn: (commentId: string) => api.deleteMessagesCommentsByIdV1(message.id, commentId).then((x) => x.data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGE_COMMENTS_DETAILS(projectId, message.id) });
      await onFetchNewComments();
    },
    onError() {
      showFlashToast({ type: "error", title: t("component.community-post.comments.delete.error") });
    },
  });

  const [showTranslation, showTranslationHandler] = useBool();
  const translation = useQuery({
    queryKey: QUERY_KEYS.MESSAGE_TRANSLATION(projectId, message.id, sessionUser.language.id),
    queryFn: () => api.getMessagesTranslationsDetailsV2(message.id, sessionUser.language.id),
    retry: false,
    enabled: showTranslation,
  });

  const markAsRead = useMutation({
    mutationFn: () => api.postMessagesReadV1(message.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_FEED_STATS(projectId) });
    },
    onError: () => {
      showFlashToast({ type: "error", title: t("component.community-post.mark-as-read.error") });
    },
  });

  const onGroupFollowChange = async () => {
    await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id) });
  };

  const onNewLikesOrCommentsOnPost = useCallback(
    (
      ...args: [
        {
          entityId: string;
          entityType: "message" | "comment" | "poll" | "survey";
          likeTotal: number;
          commentTotal: number;
          authorId: string;
        },
      ]
    ) => {
      if (!isExpanded || args[0].authorId === sessionUser.id) {
        return;
      }

      void queryClient.setQueryData<ApiResponseType<"getMessagesDetailsV2"> | undefined>(
        QUERY_KEYS.MESSAGES_DETAILS(projectId, message.id),
        (oldData) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            totalLikeCount: args[0].likeTotal,
            totalCommentCount: args[0].commentTotal,
          };
        },
      );
    },
    [isExpanded, sessionUser, queryClient, message.id, projectId],
  );
  useSignalRSubscription(signalRConnection, "UpdateMessageLikeAndCommentsCount", onNewLikesOrCommentsOnPost);

  const onNewCommentLike = useCallback(
    (...args: [{ entityId: string; likeTotal: number; authorId: string }]) => {
      if (!isExpanded || args[0].authorId === sessionUser.id) {
        return;
      }

      void queryClient.setQueryData<InfiniteData<CommentDtoPaginationResultDto> | undefined>(
        QUERY_KEYS.MESSAGES_COMMENTS(projectId, message.id),
        (oldData) => {
          if (!oldData) {
            return;
          }

          return {
            ...oldData,
            pages: [
              ...oldData.pages.map((page) => ({
                ...page,
                items: page.items.map((item: CommentDto) =>
                  item.id === args[0].entityId ? { ...item, totalLikesCount: args[0].likeTotal } : item,
                ),
              })),
            ],
          };
        },
      );
    },
    [isExpanded, sessionUser, queryClient, message.id, projectId],
  );
  useSignalRSubscription(signalRConnection, "UpdateCommentLikeCount", onNewCommentLike);

  const onNewComment = useCallback(
    (...args: [{ entityId: string; entityType: "message" | "comment" | "poll" | "survey"; authorId: string }]) => {
      if (!isExpanded || args[0].authorId === sessionUser.id) {
        return;
      }

      newCommentPillHandler.setTrue();
    },
    [isExpanded, sessionUser, newCommentPillHandler],
  );
  useSignalRSubscription(signalRConnection, "NewCommentOnMessage", onNewComment);

  async function onFetchNewComments() {
    newCommentPillHandler.setFalse();
    await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.MESSAGES_COMMENTS(projectId, message.id) });
    await comments.refetch();
  }

  if (isExpanded && !comments.isFetched) {
    void comments.refetch();
  }

  const allLikes = useMemo(() => {
    return likes.data?.pages.flatMap((x) => x.items ?? []) ?? [];
  }, [likes]);

  const allComments = useMemo(() => {
    return comments.data?.pages.flatMap((x) => x.items ?? []) ?? [];
  }, [comments]);

  return {
    post: messageError ? null : messageDetails,
    likes: allLikes,
    fetchLikes: likes,
    onLike: likePost.mutateAsync,
    onUnlike: unlikePost.mutateAsync,
    onLikeComment: likeComment.mutateAsync,
    onUnlikeComment: unlikeComment.mutateAsync,
    comments: allComments,
    fetchComments: comments,
    isMarkingAsRead: markAsRead.isPending,
    commentPost: createComment,
    markAsRead: markAsRead.mutateAsync,
    deletePost: deletePost.mutateAsync,
    archivePost: archivePost.mutateAsync,
    copyQuickReply: createQuickReplyLink.mutateAsync,
    editPost: editPost,
    editComment: editComment,
    deleteComment: deleteComment.mutateAsync,
    activeTab,
    setActiveTab,
    isSubmitting: updateMessage.isPending || isUploadingImage || isUploadingDocument,
    analyze,
    onAnalyze: analyzeHandler.setTrue,
    onCloseAnalyze: analyzeHandler.setFalse,
    analysis: {
      topics,
      sentiment,
    },
    sessionUser,
    translation: showTranslation ? translation.data?.data : undefined,
    translationIsLoading: translation.isLoading,
    onTranslate: showTranslationHandler.setTrue,
    onHideTranslation: showTranslationHandler.setFalse,
    onGroupFollowChange,
    showNewCommentPill,
    onFetchNewComments,
  };
}
