import type { ImageDto } from "api/types";
import iconFileAttachment01 from "assets/icons/file-attachment-01.svg";
import { Icon } from "components/Icon/Icon";
import { MediaXButton } from "components/Media/MediaXButton";
import { Subtitle2 } from "components/Text/Text";
import { formatFileSize } from "helpers/file-size";
import { twJoin } from "tailwind-merge";

interface PdfProps {
  previewImage?: ImageDto;
  fileName?: string;
  fileSize?: number;
  onClick?: () => void;
  onDelete?: () => void;
  isInvalid?: boolean;
}

export function Pdf({ previewImage, fileName, fileSize, isInvalid, onClick, onDelete }: PdfProps): React.ReactNode {
  const Component = onClick ? "button" : "div";

  return (
    <Component
      className={twJoin(
        "flex items-center gap-2 rounded-lg bg-grey-lightest p-4 text-left",
        isInvalid ? "ring-2 ring-red-dark" : undefined,
        onClick ? "hocus:bg-grey-lighter/50" : undefined,
      )}
      onClick={onClick}
      type={onClick ? "button" : undefined}
    >
      {previewImage ? (
        <div
          className="size-12 bg-contain bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${previewImage.url})`,
          }}
        />
      ) : (
        <div className="flex justify-center px-1 text-black">
          <Icon name={iconFileAttachment01} size={32} />
        </div>
      )}
      <div className="flex flex-col text-black">
        <Subtitle2 className="line-clamp-1 break-all">{fileName || "..."}</Subtitle2>
        {fileSize ? (
          <p className="text-grey-dark">
            {formatFileSize(fileSize, 0)}
            {fileName?.toLowerCase().endsWith("pdf") ? " PDF" : ""}
          </p>
        ) : null}
      </div>
      {onDelete ? (
        <div className="flex items-center justify-center px-2">
          <div className="relative">
            <MediaXButton
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onDelete();
              }}
            />
          </div>
        </div>
      ) : null}
    </Component>
  );
}
