import type { ImageDto } from "api/types";
import iconChevronLeft from "assets/icons/chevron-left.svg";
import iconChevronRight from "assets/icons/chevron-right.svg";
import { Icon } from "components/Icon/Icon";
import { AnimatePresence, motion } from "framer-motion";
import { preloadImage } from "helpers/image";
import { useKey } from "hooks/useKey";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import { twJoin } from "tailwind-merge";

interface CarouselProps {
  images: ImageDto[];
  rounded?: "full" | "top" | "bottom";
}

const variants = {
  enter: (direction: "left" | "right") => {
    return {
      x: direction === "right" ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: "left" | "right") => {
    return {
      zIndex: 0,
      x: direction === "left" ? 1000 : -1000,
      opacity: 0,
    };
  },
};

export function Carousel({ images, rounded }: CarouselProps): React.ReactNode {
  const { t } = useTranslation();
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [direction, setDirection] = useState<"left" | "right">("right");
  const [zoomedImage, setZoomedImage] = useState<ImageDto | undefined>(undefined);

  const handleNext = () => {
    setDirection("right");
    setActiveSlide((prevSlide) => (prevSlide + 1 === images.length ? 0 : prevSlide + 1));
  };

  const handlePrev = () => {
    setDirection("left");
    setActiveSlide((prevSlide) => (prevSlide - 1 < 0 ? images.length - 1 : prevSlide - 1));
  };

  const handleDotClick = (idx: number) => {
    setDirection(idx > activeSlide ? "right" : "left");
    setActiveSlide(idx);
  };

  useKey(
    "Escape",
    () => {
      setZoomedImage(undefined);
    },
    zoomedImage !== undefined,
  );

  useEffect(() => {
    const nextImage = images[activeSlide + 1];
    if (nextImage) {
      preloadImage(nextImage.url);
    }
  }, [activeSlide, images]);

  return (
    <div
      className={twJoin(
        "group/carousel relative flex size-full cursor-pointer items-center justify-center overflow-hidden bg-grey-light",
        rounded === "full"
          ? "rounded-lg"
          : rounded === "top"
            ? "rounded-t-lg"
            : rounded === "bottom"
              ? "rounded-b-lg"
              : undefined,
      )}
    >
      <AnimatePresence initial={false} custom={direction}>
        <motion.div className="absolute inset-0" layoutId={images[activeSlide].url} />
        <motion.img
          key={activeSlide}
          src={images[activeSlide].url}
          custom={direction}
          initial={"enter"}
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.1 },
          }}
          variants={variants}
          className="absolute size-full object-cover"
          onClick={() => setZoomedImage(images[activeSlide])}
        />
      </AnimatePresence>
      {images.length > 1 ? (
        <>
          <div className="absolute bottom-5 left-1/2 z-30 flex -translate-x-1/2 space-x-3">
            {images.map((img, idx) => (
              <button
                key={img.id}
                type="button"
                className={twJoin(
                  "size-3 rounded-full transition-colors duration-500 focus:ring-2 focus:ring-white",
                  idx === activeSlide ? "bg-white/80" : "bg-grey-darkest/60",
                )}
                aria-current="true"
                aria-label={`Slide ${idx + 1}`}
                onClick={() => handleDotClick(idx)}
              />
            ))}
          </div>
          <button
            type="button"
            className="group/button absolute start-0 top-1/2 z-30 mx-4 hidden size-8 cursor-pointer items-center justify-center rounded-full bg-aop-basic-blue pr-0.5 text-white focus:outline-none group-hover/carousel:inline-flex group-hover/button:bg-aop-basic-blue-dark group-focus/button:outline-none group-focus/button:ring-2 group-focus/button:ring-white"
            onClick={handlePrev}
          >
            <Icon name={iconChevronLeft} size={24} />
            <span className="sr-only">{t("common.action.previous")}</span>
          </button>
          <button
            type="button"
            className="group/button absolute end-0 top-1/2 z-30 mx-4 hidden size-8 cursor-pointer items-center justify-center rounded-full bg-aop-basic-blue pl-0.5 text-white focus:outline-none group-hover/carousel:inline-flex group-hover/button:bg-aop-basic-blue-dark group-focus/button:outline-none group-focus/button:ring-2 group-focus/button:ring-white"
            onClick={handleNext}
          >
            <Icon name={iconChevronRight} size={24} />
            <span className="sr-only">{t("common.action.next")}</span>
          </button>{" "}
        </>
      ) : null}
      {ReactDOM.createPortal(
        <AnimatePresence>
          {zoomedImage !== undefined ? (
            <motion.div
              className="fixed inset-0 z-50 flex min-h-screen scale-100 items-center justify-center overflow-y-auto"
              role="button"
              aria-hidden
              onClick={() => setZoomedImage(undefined)}
            >
              <motion.div
                className="fixed inset-0 bg-black"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.8 }}
                exit={{ opacity: 0 }}
              />

              <motion.div className="z-0 flex h-screen !w-screen items-center justify-center p-8 md:p-16">
                <motion.img
                  className="block max-h-full max-w-full select-none rounded bg-grey-lightest shadow-xl"
                  src={zoomedImage.url}
                  alt={"description" in zoomedImage ? zoomedImage.description || "" : ""}
                  layoutId={zoomedImage.url}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              </motion.div>
            </motion.div>
          ) : null}
        </AnimatePresence>,
        document.body,
      )}
    </div>
  );
}
